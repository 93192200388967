import * as _signalR from "@microsoft/signalr";
import type { HubConnection } from "@microsoft/signalr";
import { useAuthStore } from "~/store/auth";

export class HeltiaSignalR {
  connection: HubConnection;
  connectionId: string | null = null;
  oldConnectionId: string | null = null;

  constructor(signalRBaseUrl: string, hubName: string) {
    const logLevel =
      useRuntimeConfig().public.env === "production"
        ? _signalR.LogLevel.Error
        : _signalR.LogLevel.Debug;

    const authStore = useAuthStore();
    this.connection = new _signalR.HubConnectionBuilder()
      .withAutomaticReconnect([
        1000, 2000, 3000, 4000, 5000, 5000, 5000, 5000, 5000, 10000, 10000,
        10000, 10000, 10000, 30000, 30000, 30000,
      ])
      .configureLogging(logLevel)
      .withUrl(`${signalRBaseUrl}/${hubName}`, {
        skipNegotiation: false,
        transport: _signalR.HttpTransportType.WebSockets,
        withCredentials: false,
        accessTokenFactory: async () => {
          return (await authStore.getOrRefreshAccessToken()) ?? "";
        },
      })
      .build();

    this.connection.onreconnecting((error) => {
      this.oldConnectionId = this.connectionId;
    });

    this.connection.onreconnected(async (newConnectionId) => {
      this.connectionId = newConnectionId;
      if (this.oldConnectionId) {
        this.connection.send(
          "ClientReconnected",
          this.oldConnectionId,
          newConnectionId
        );
        this.oldConnectionId = null;
      }
    });
  }

  on(event: string, callback: (...args: any[]) => void) {
    this.connection.off(event);
    this.connection.on(event, callback);
    return this;
  }

  call(...args: any[]) {
    if (this.connection?.state === _signalR.HubConnectionState.Connected) {
      if (args.length === 1) {
        this.connection.send(args[0]);
      } else {
        const eventName = args.shift();
        this.connection.send(eventName, ...args);
      }
    } else {
      console.log("Connection is not established");
    }
  }

  async connect() {
    await this.connection.start();
    this.connectionId = this.connection.connectionId;
  }

  async leave() {
    try {
      await this.connection.stop();
    } catch (error) {}
  }

  async reconnect() {
    await this.leave();
    await this.connect();
  }
}
