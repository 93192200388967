import validate from "D:/a/r1/a/_SalusWebsite/node_modules/nuxt/dist/pages/runtime/validate.js";
import _001_45redirection_45global from "D:/a/r1/a/_SalusWebsite/middleware/001.redirection.global.ts";
import _002_45auth_45guard_45global from "D:/a/r1/a/_SalusWebsite/middleware/002.auth-guard.global.ts";
import _003_45flow_45management_45global from "D:/a/r1/a/_SalusWebsite/middleware/003.flow-management.global.ts";
import manifest_45route_45rule from "D:/a/r1/a/_SalusWebsite/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _001_45redirection_45global,
  _002_45auth_45guard_45global,
  _003_45flow_45management_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}