import { defineStore } from "pinia";
import Tracking from "~/utils/tracking";
import { useAuthStore } from "~/store/auth";
import { useQuestionnaireStore } from "~/store/questionnaire";
import { useVerticalStore } from "~/store/vertical-selection";
import {
  requestNameBirthdayActivation,
  requestCodeActivation,
  requestEnrollment,
  startOnboarding,
  updateSponsorship,
} from "~/helpers/api/activation";
import {
  getUserQuestionnaireSessions,
  postAnswers,
} from "~/helpers/api/questionnaire";
import type { ActivationForm } from "~/model/activation";
import type { User } from "~/model/user";
import { useUserStore } from "../user";
import {
  formatISO,
  getYear,
  isAfter,
  isValid,
  parseISO,
  subYears,
} from "date-fns";

export const useActivationStore = defineStore("activation", {
  state: () => ({
    _loading: false as boolean,
    _form: {
      firstname: "",
      lastname: "",
      birthday: null,
      marketingConsent: false,
    } as ActivationForm | null,
    _viewState: "initial",
    activationCode: "",
  }),
  actions: {
    initialize() {
      var authStore = useAuthStore();
      if (authStore.authResponse?.firstname != null) {
        this._form.firstname = authStore.authResponse.firstname;
        this._form.lastname = authStore.authResponse.lastname;
      }
    },
    isFirstnameValid() {
      var errors = [
        this._form.firstname.length < 2
          ? "İsim alanı en az 2 karakter olmalıdır."
          : null,
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    isLastnameValid() {
      var errors = [
        this._form.lastname.length < 2
          ? "Soyisim alanı en az 2 karakter olmalıdır."
          : null,
      ];

      return errors?.filter((x) => x && x.length > 0);
    },
    isBirthdayValid() {
      var errors = [];
      const selectedDate = this._form.birthday
        ? parseISO(this._form.birthday)
        : null;
      const today = formatISO(new Date());
      const minBirthDate = subYears(today, 18);

      if (this._form.birthday == null || getYear(selectedDate) < 1900) {
        errors = ["Geçerli bir tarih seçiniz."];
      } else if (!isValid(selectedDate)) {
        errors = ["Geçerli bir tarih seçiniz."];
      } else if (isAfter(selectedDate, today)) {
        errors = ["Bugünden ileri bir tarih seçemezsiniz."];
      } else if (isAfter(selectedDate, minBirthDate)) {
        errors = ["En az 18 yaşında olmalısınız."];
      } else {
        errors = [null];
      }

      return errors?.filter((x) => x && x.length > 0);
    },
    setFirstName(firstname: string) {
      this._form.firstname = firstname;
    },
    setLastName(lastname: string) {
      this._form.lastname = lastname;
    },
    setBirthday(birthday: string | null) {
      this._form.birthday = birthday;
    },
    setMarketingConsent(marketingConsent: boolean) {
      this._form.marketingConsent = marketingConsent;
    },
    changeViewState(viewState: string) {
      this._viewState = viewState;
    },
    setFormData(data: ActivationForm) {
      this._form = data;
    },
    async requestActivation() {
      this._loading = true;
      var user = null;
      var authStore = useAuthStore();
      var questionnaireStore = useQuestionnaireStore();
      var verticalStore = useVerticalStore();

      const tracking = Tracking.getInstance();

      tracking.activation("Requested", {
        Name: this._form.firstname,
        Lastname: this._form.lastname,
        Birthday: this._form.birthday,
      });

      try {
        user = await requestNameBirthdayActivation(
          this._form.firstname,
          this._form.lastname,
          this._form.birthday,
          this._form.marketingConsent
        );

        if (!user.isActivated()) {
          user = await this.requestCodeActivation("$B2C");

          tracking.activationCode("Successfull", {
            AcquisitionWebUrl: document.referrer,
          });
        }

        authStore.user = user;

        tracking.setUser(user);

        if (user.isActivated() || user.isDeactivated()) {
          tracking.activation("Successfull", {
            Name: this._form.firstname,
            Lastname: this._form.lastname,
            Birthday: this._form.birthday,
          });
          this.clearForm();
        }
      } catch (error) {
        console.error(error);
        tracking.activation("Error", {
          Name: this._form.firstname,
          Lastname: this._form.lastname,
          Birthday: this._form.birthday,
        });
      }
      if (user != null && authStore.isAuthFlowHighway()) {
        var sessions = await getUserQuestionnaireSessions(
          authStore.token,
          "Initiation"
        );
        if (sessions) {
          var postAnswerResults = await postAnswers(
            authStore.token,
            sessions[0].id,
            questionnaireStore.answers
          );
          if (postAnswerResults) {
            await requestEnrollment(verticalStore.selectedEnumValue);
            if (user.planV2.flowType == "Restricted") {
              await startOnboarding(user.enrollments);
            }
            this.changeViewState("completed");
          }
        }
        this._loading = false;
      }
    },
    async requestCodeActivation(code: String): Promise<User> {
      try {
        var authStore = useAuthStore();
        const user = await requestCodeActivation(code);
        authStore.user = user;
        this.activationCode = "";
        const userStore = useUserStore();
        await userStore.refreshUserProfile();
        return user;
      } catch (error) {
        return null;
      }
    },
    async updateSponsorship(code: String): Promise<Boolean> {
      try {
        var authStore = useAuthStore();
        await updateSponsorship(code);
        authStore.refreshUser();
        this.activationCode = "";
        const userStore = useUserStore();
        await userStore.refreshUserProfile();
        return true;
      } catch (error) {
        return false;
      }
    },
    isFormValid() {
      return (
        this.isFirstnameValid().length === 0 &&
        this.isLastnameValid().length === 0 &&
        this.isBirthdayValid().length === 0
      );
    },
    clearForm() {
      this._form = {
        firstname: "",
        lastname: "",
        birthday: null,
        marketingConsent: false,
      };
    },
  },
  getters: {
    getFirstName(): string {
      return this._form.firstname;
    },
    getLastName(): string {
      return this._form.lastname;
    },
    getBirthday(): string | null {
      return this._form.birthday;
    },
    getMarketingConsent(): boolean {
      return this._form.marketingConsent;
    },
    getViewState(): any {
      return this._viewState;
    },
  },
});
