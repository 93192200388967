<!--
    To use snackbar, you need to get an instance with useSnackbar() and
    call the show method with the desired parameters. Example is below:
    this.snackbar.add({
        title: "Title",
        text: "Text Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec turpis tincidunt ultricies.",
        indicatorType: MeetingCardType.Warning,
        buttonLabel: "Button",
        buttonAction: "addProfessional",
        duration: 5000, //milliseconds
      });
-->

<template>
  <AppCard
    :card-type="type"
    :show-side-indicator="false"
    :always-show-borders="true"
  >
    <AppBaseRow
      containerType="background"
      gap="xsmall"
      justify="between"
      items="start"
      color="bg-neutral-60"
    >
      <AppBaseRow
        containerType="base"
        gap="xsmall"
        justify="start"
        items="start"
        color="bg-neutral-60"
      >
        <div class="">
          <div
            :class="`h-8 w-8  ${getIndicatorColor()} rounded-[32px] justify-center items-center grid`"
          >
            <span :class="`${getIndicatorIcon()} text-surface-10`" />
          </div>
        </div>
        <AppBaseColumn
          color="bg-neutral-60 "
          justify="start"
          items="start"
          gap="xsmall"
        >
          <span v-if="title != ''" class="l2 text-surface-10">{{ title }}</span>
          <span v-if="text != ''" class="b3 text-surface-10">{{ text }}</span>
          <ButtonPrimarySmall
            v-if="buttonLabel != ''"
            :label="buttonLabel"
            @click="onAction()"
          />
        </AppBaseColumn>
      </AppBaseRow>
      <span class="icon-remove text-surface-10" @click="onDismiss()" />
    </AppBaseRow>
  </AppCard>
</template>

<script lang="ts">
import { MeetingCardType } from "../card/type";

enum snackbarActionType {
  addProfessional = "addProfessional",
}
export default {
  setup() {
    const snackbar = useSnackbar();
    return { snackbar };
  },
  props: {
    type: {
      type: String as () => MeetingCardType,
      default: MeetingCardType.Default,
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    buttonLabel: {
      type: String,
      default: "",
    },
    buttonAction: {
      type: String,
      default: "",
    },
  },
  methods: {
    getIndicatorColor() {
      switch (this.type) {
        case MeetingCardType.Default:
          return "bg-primaryV2";
        case MeetingCardType.Warning:
          return "bg-system-warning-base";
        case MeetingCardType.Alert:
          return "bg-system-alert-base";
        case MeetingCardType.Success:
          return "bg-system-success-base";
      }
    },
    getIndicatorIcon() {
      switch (this.type) {
        case MeetingCardType.Default:
          return "icon-info";
        case MeetingCardType.Warning:
          return "icon-info";
        case MeetingCardType.Alert:
          return "icon-warning";
        case MeetingCardType.Success:
          return "icon-success";
      }
    },
    onAction() {
      if (this.buttonAction === snackbarActionType.addProfessional) {
        console.log("addProfessional");
      }
      this.snackbar.clear();
    },
    onDismiss() {
      this.snackbar.clear();
    },
  },
};
</script>

<style scoped>
[class*="icon-"]:before {
  @apply !inline mr-0;
}
</style>
