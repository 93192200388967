import { defineStore } from "pinia";
import axios from "axios";
import {
  postAnswers,
  getUserQuestionnaireSessions,
  evaluateInitiation,
} from "~/helpers/api/questionnaire";
import { startOnboarding } from "~/helpers/api/activation";
import { useVerticalStore } from "../vertical-selection";
import { useAuthStore } from "~/store/auth";
import { Error } from "~/model/common";
import Tracking from "~/utils/tracking";

const questionnaireSteps = ["welcome", "questions", "result"] as const;
type QuestionnaireStep = (typeof questionnaireSteps)[number];

type QuestionnaireType = "onboarding" | "test" | "verticalInitiation";

export const useQuestionnaireStore = defineStore("questionnaire", {
  state: () => ({
    _loading: false as boolean,
    _currentQuestion: null as QuestionDto | null,
    _questionnaire: null as QuestionnaireDto | null,
    _answers: [] as AnswerDto[] | null,
    _questionnaireStep: null as QuestionnaireStep,
    _questionnaireType: null as QuestionnaireType,
    _shouldStoreSession: false as boolean,
    _result: 0 as number,
    _errors: [] as Error[] | null,
    _doNotClearAnswers: false as boolean,
  }),
  getters: {
    questionnaire: (state) => {
      return state._questionnaire;
    },
    loading: (state) => {
      return state._loading || state._questionnaire === null;
    },
    answers: (state) => {
      return state._answers;
    },
    currentQuestion: (state) => {
      return state._currentQuestion;
    },
    questionnaireStep: (state) => {
      return state._questionnaireStep;
    },
    questionnaireType: (state) => {
      return state._questionnaireType;
    },
    result: (state) => {
      return state._result;
    },
    shouldStoreSession: (state) => {
      return state._shouldStoreSession;
    },
    currentSelectedOptions: (state) => {
      const answer = state._answers?.find(
        (a) => a.index === state._currentQuestion?.index
      );

      return findSelectedOptions(answer);
    },
    isAllQuestionsAnswered: (state) => {
      return state._answers?.length === state._questionnaire?.questions.length;
    },
    isCurrentQuestionAnswered: (state) => {
      const answer = state._answers?.find(
        (a) => a.index === state._currentQuestion?.index
      );

      const givenInput = findSelectedOptions(answer);
      return givenInput.length > 0 && givenInput[0] !== "";
    },
    isLastQuestion: (state) => {
      return (
        state._currentQuestion?.index ===
        state._questionnaire?.questions.length - 1
      );
    },
    isFirstQuestion: (state) => {
      return state._currentQuestion?.index === 0;
    },
  },
  actions: {
    async initializeQuestionnaireWithUrl(
      url: string,
      questionnaireType: QuestionnaireType,
      questionnaireStep: QuestionnaireStep,
      ssrQuestionnaire?: QuestionnaireDto
    ) {
      this._loading = true;

      let questionnaire: QuestionnaireDto;
      if (ssrQuestionnaire) {
        questionnaire = ssrQuestionnaire;
      } else {
        var questionnaireResponse = await axios.get(url);
        questionnaire = questionnaireResponse.data as QuestionnaireDto;
      }

      this._questionnaire = questionnaire;
      if (!this._doNotClearAnswers) {
        this._answers = [];
      }

      this._doNotClearAnswers = false;
      this._currentQuestion = this._questionnaire.questions[0];
      this._questionnaireStep = questionnaireStep;
      this._questionnaireType = questionnaireType;
      this._loading = false;
    },
    async initializeQuestionnaire(name: string) {
      const questionnaireType =
        name === "Initiation" ? "onboarding" : "verticalInitiation";
      await this.initializeQuestionnaireWithUrl(
        `/api/questionnaire/${name}`,
        questionnaireType,
        "questions"
      );
      const tracking = Tracking.getInstance();

      if (questionnaireType === "onboarding") {
        tracking.onboardingQuestionnare("Loaded", {
          Questionnaire: name,
        });
      } else {
        this._shouldStoreSession = true;
        tracking.questionnaire("Loaded", {
          Questionnaire: name,
        });
      }
    },
    async initializeTestQuestionnaire(
      id: string,
      ssrQuestionnaire: QuestionnaireDto
    ) {
      if (id === this._questionnaire?.id) {
        if (this._answers.length !== 0) {
          this._answers = [];

          this._currentQuestion = this._questionnaire.questions[0];
          this._questionnaireStep = "welcome";
          this._questionnaireType = "test";
        }

        return;
      }

      await this.initializeQuestionnaireWithUrl(
        `/api/questionnaire/getById/${id}`,
        "test",
        "welcome",
        ssrQuestionnaire
      );
      const tracking = Tracking.getInstance();
      tracking.webTest("Loaded", {
        Questionnaire: id,
        "Test Name": ssrQuestionnaire.alias,
      });
    },
    setAnswers(options: string[]) {
      const { index, type } = this._currentQuestion!;

      let existingAnswer = this._answers.find((a) => a.index === index);

      if (!existingAnswer) {
        existingAnswer = generateAnswer(this._currentQuestion, options);
        this._answers.push(existingAnswer);
      } else {
        const answerUpdateMap = {
          SingleSelection: () =>
            (existingAnswer.answerSingleSelection = options[0]),
          Numeric: () => (existingAnswer.answerNumeric = parseInt(options[0])),
          FreeText: () => (existingAnswer.answerText = options[0]),
          Sorting: () => (existingAnswer.answerSorting = options),
          MultiSelection: () => {
            const optionIndex =
              existingAnswer.answerMultipleSelection!.findIndex(
                (o) => o === options[0]
              );
            if (optionIndex !== -1) {
              existingAnswer.answerMultipleSelection!.splice(optionIndex, 1);
            } else {
              existingAnswer.answerMultipleSelection!.push(options[0]);
            }
          },
        };

        const updateFunction = answerUpdateMap[type];
        updateFunction?.();
      }
    },
    setAllAnswers(session) {
      this._answers = session.answers;
      this._doNotClearAnswers = true;
    },
    async nextQuestion() {
      const nextQuestionIndex = this._currentQuestion!.index + 1;
      this._currentQuestion = this._questionnaire!.questions[nextQuestionIndex];
    },
    async previousQuestion() {
      const previousQuestionIndex = this._currentQuestion!.index - 1;
      this._currentQuestion =
        this._questionnaire!.questions[previousQuestionIndex];
    },
    async startQuestionnaire() {
      this._questionnaireStep = "questions";
    },
    async endQuestionnaire(skipResult: boolean) {
      this._loading = true;

      let totalScore = this._answers.reduce((total, answer) => {
        if (answer.type === "SingleSelection") {
          const score = answer.scoredOptions[answer.answerSingleSelection!];
          return total + score;
        }
        return total;
      }, 0);

      this._result = isNaN(totalScore) ? 0 : totalScore;

      if (!skipResult) this._questionnaireStep = "result";

      if (this._shouldStoreSession == true) {
        const authStore = useAuthStore();

        var sessions = await getUserQuestionnaireSessions(
          authStore.token,
          this._questionnaire!.name
        );
        if (sessions) {
          var postAnswerResults = await postAnswers(
            authStore.token,
            sessions[0].id,
            this._answers
          );
          if (postAnswerResults == null) {
            this._errors.push(new Error("Bir hata oluştu.", "postAnswers"));
          } else {
            var verticalStore = useVerticalStore();

            await evaluateInitiation(
              authStore.token,
              verticalStore.selectedEnumValue
            );

            await authStore.refreshUser();

            if (authStore.$state.user.userState == "InitialVerticalSelected") {
              await startOnboarding(authStore.$state.user.enrollments);
            }
          }
        }
      }

      this._loading = false;
    },
    async previousStep() {
      const index = questionnaireSteps.indexOf(this._questionnaireStep);
      if (index > 0) {
        this._questionnaireStep = questionnaireSteps[index - 1];
      }
    },
    async finishQuestionnaire() {
      this.$reset();
    },
    async clearQuestionnaireStore() {
      this.$reset();
    },
  },
});

function generateAnswer(currentQuestion, options) {
  const answer: AnswerDto = {
    index: currentQuestion!.index,
    options: currentQuestion!.options,
    type: currentQuestion!.type,
    answerSingleSelection:
      currentQuestion.type === "SingleSelection" ? options[0] : null,
    answerMultipleSelection:
      currentQuestion.type === "MultiSelection" ? options : null,
    answerNumeric:
      currentQuestion.type === "Numeric" ? parseInt(options[0]) : null,
    answerText: currentQuestion.type === "FreeText" ? options[0] : null,
    answerSorting: currentQuestion.type === "Sorting" ? options : null,
    scoredOptions: currentQuestion.scoredOptions,
  };
  return answer;
}

function findSelectedOptions(answer: AnswerDto) {
  if (!answer) return [];

  switch (answer.type) {
    case "SingleSelection":
      return [answer.answerSingleSelection];
    case "MultiSelection":
      return answer.answerMultipleSelection;
    case "Numeric":
      return [answer.answerNumeric.toString()];
    case "FreeText":
      return [answer.answerText];
    case "Sorting":
      return answer.answerSorting;
    default:
      return [];
  }
}
