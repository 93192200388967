export enum QuestionnaireStates {
  Initial = "Initial",
  Completed = "Completed",
}

export class QuestionnaireState {
  name: string;
  state: QuestionnaireStates;

  constructor(name: string, state: QuestionnaireStates) {
    this.name = name;
    this.state = state;
  }
}
