import { axiosInstance } from "~/plugins/axios";
import { MeetingInvoicingStatus, type Meeting } from "~/model/meeting";
import type { MeetingCreateRequest } from "~/model/meeting/createRequest";
import type { MeetingDeleteRequest } from "~/model/meeting/deleteRequest";
import type { MeetingFeedback } from "~/model/meeting/feedbackRequest";
import type { ClientFeedbackRequest } from "~/model/meeting/clientFeedbackRequest";
import type { MeetingInsufficientFundsRequest } from "~/model/meeting/insufficientFundsRequest";
import ApiUtils from "~/utils/api";
import type { TimeSlot } from "~/model/matching/preferences";
import type { InvoiceFilter } from "~/store/invoice";
import type { InvoicingMeeting } from "~/model/meeting/invoicingMeeting";
import { startOfDay, endOfDay } from "date-fns";

const apiPathPrefix = "/api/meeting";

export const getMeetings = async (
  participantId: String | null,
  windowStart: Date | null,
  windowEnd: Date | null,
  hasFeedback: boolean | null
): Promise<Meeting[]> => {
  const queryParams = {
    participantId: participantId,
    windowStart: windowStart,
    windowEnd: windowEnd,
    hasFeedback: hasFeedback,
  };
  const queryString = ApiUtils.toQueryString(queryParams);
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/List${queryString}`
  );
  return data;
};

export const createMeeting = async (
  meeting: MeetingCreateRequest
): Promise<Meeting> => {
  const { data } = await axiosInstance.post(`${apiPathPrefix}/Create`, meeting);
  return data;
};

export const getFutureMeetings = async (
  distinct: boolean | null
): Promise<Meeting[]> => {
  const queryParams = ApiUtils.toQueryString({ distinct: distinct });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/FutureMeetings${queryParams}`
  );
  return data;
};

export const getPastMeetings = async (): Promise<Meeting[]> => {
  const { data } = await axiosInstance.get(`${apiPathPrefix}/PastMeetings`);
  return data;
};

export const updateMeeting = async (meeting: Meeting): Promise<boolean> => {
  const queryParams = ApiUtils.toQueryString({ id: meeting.id });
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/Update${queryParams}`,
    meeting
  );
  return data;
};

export const deleteMeeting = async (
  meetingDeleteRequest: MeetingDeleteRequest
): Promise<Meeting> => {
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/Remove`,
    meetingDeleteRequest
  );
  return data;
};

export const submitProviderMeetingFeedback = async (
  meetingFeedbackRequest: MeetingFeedback
): Promise<Meeting> => {
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/ProviderFeedback`,
    meetingFeedbackRequest
  );
  return data;
};

export const submitClientMeetingFeedback = async (
  clientFeedbackRequest: ClientFeedbackRequest
): Promise<Meeting> => {
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/ClientFeedback`,
    clientFeedbackRequest
  );
  return data;
};

export const scheduleInsufficientFundsCommunications = async (
  insufficientFundsRequest: MeetingInsufficientFundsRequest
): Promise<boolean> => {
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/InsufficientFunds`,
    insufficientFundsRequest
  );
  return data;
};

export const getMeetingCount = async (
  otherUserId: string,
  onlyActive: boolean
): Promise<number> => {
  const queryParams = ApiUtils.toQueryString({ otherUserId, onlyActive });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/Count${queryParams}`
  );
  return data;
};
export const getSchedulingSlots = async (
  providerId: string
): Promise<TimeSlot[]> => {
  const queryParams = ApiUtils.toQueryString({ providerId });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetSchedulingSlots${queryParams}`
  );
  return data;
};

export const updateMeetingInvoicingStatus = async (
  meetingId: string,
  value: boolean
): Promise<boolean> => {
  let params = {
    meetingId,
    value,
  };

  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/UpdateInvoicingStatus`,
    params
  );

  return data;
};

export const getInvoicingMeetings = async (
  filter: InvoiceFilter
): Promise<InvoicingMeeting[]> => {
  const adjustedFilter: InvoiceFilter = {
    ...filter,
    windowStart: filter.windowStart
      ? startOfDay(new Date(filter.windowStart)).toISOString()
      : undefined,
    windowEnd: filter.windowEnd
      ? endOfDay(new Date(filter.windowEnd)).toISOString()
      : undefined,
  };

  const queryParams = ApiUtils.toQueryString(adjustedFilter);
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/GetInvoicingMeetings${queryParams}`
  );

  return data;
};
