import type { UserType } from "../..";

export class MeetingRight {
  explanation?: string;
  createdAt?: Date;
  updatedAt?: Date;
  type: MeetingRightType;
  state: MeetingRightState;
  expirationType: MeetingRightExpirationType;
  expirationDate?: Date;
  usageDate?: Date;
  relatedMeetingId?: string;
  applicableProviderType?: UserType;
}

export class InitialMeetingRightPolicy {
  expirationType?: MeetingRightExpirationType;
  type: MeetingRightType;
  expirationDate?: Date;
  quantity: number;
  applicableProviderType?: UserType;
}

export enum MeetingRightType {
  InitialGift = "InitialGift",
  Prepaid = "Prepaid",
  Correction = "Correction",
  SystemGift = "SystemGift",
  UserGift = "UserGift",
  Purchased = "Purchased",
}

export enum MeetingRightState {
  Active = "Active",
  Used = "Used",
  Expired = "Expired",
  Refunded = "Refunded",
}

export enum MeetingRightExpirationType {
  None = "None",
  FixedDate = "FixedDate",
  LinkedToPlan = "LinkedToPlan",
}
