import { useVerticalStore } from "@/store/vertical-selection";
import { useQuestionnaireStore } from "@/store/questionnaire";
import { useAuthStore } from "@/store/auth";
import { useActivationStore } from "@/store/activation";
import { useMatchingStore } from "~/store/matching";
import { AuthFlow } from "~/model/user";
import { useSchedulingStore } from "@/store/scheduling";
import { getUserQuestionnaireSessions } from "~/helpers/api/questionnaire";
import { getSignedInFlowRoute } from "~/utils/routes";

const resetNecessaryStores = (currentFlowRoute: AppFlowRoute) => {
  const authStore = useAuthStore();
  const activationStore = useActivationStore();
  const matchingStore = useMatchingStore();

  switch (currentFlowRoute?.name) {
    case "app-seni-taniyalim":
      authStore.$reset();
      break;
    case "app-kayit-ol":
      activationStore.$reset();
      break;
    case "app-uzmanla-esles":
      matchingStore.$reset();
      break;
    default:
      break;
  }
};

var isCurrentRouteDirectNavigated = false;

export default defineNuxtRouteMiddleware(async (to, from) => {
  isCurrentRouteDirectNavigated = !from.name;

  const toPath = to.path.endsWith("/") ? to.path : to.path + "/";
  const fromPath = from.path.endsWith("/") ? from.path : from.path + "/";

  const matchingFlowPath =
    appFlowRoutes.find((path) => path.name === to.name) &&
    flowPaths.find((path) => toPath.startsWith(path));

  const authStore = useAuthStore();
  const isAuthStoreInitialized = authStore.isInitialized;

  if (toPath === "/_flow/next/") {
    const appFlowRoute = appFlowRoutes.find(
      (route) => route.name === from.name
    );
    return navigateTo(appFlowRoute.nextRoute());
  } else if (toPath === "/_flow/previous/") {
    const appFlowRoute = appFlowRoutes.find(
      (route) => route.name === from.name
    );
    return navigateTo(appFlowRoute.prevRoute());
  } else if (matchingFlowPath) {
    var toRoute = appFlowRoutes.find((route) =>
      to.name.toString().includes("slug")
        ? route.name === to.name
        : route.path === toPath
    );

    if (matchingFlowPath === "/app/" && !isAuthStoreInitialized) {
      return;
    }

    const validRoute = await toRoute.validRoute(fromPath);
    isCurrentRouteDirectNavigated = false;

    if (validRoute && validRoute !== toPath) {
      return navigateTo(validRoute);
    }
  }

  if (
    fromPath === routes.signup.path &&
    (!matchingFlowPath || toPath === routes.signin.path)
  ) {
    authStore.clearState();
  }

  isCurrentRouteDirectNavigated = false;
});

const flowPaths = ["/app/", "/testler/"] as const;
type FlowPath = (typeof flowPaths)[number];

const appFlowRoutes: Array<AppFlowRoute> = [
  {
    name: routes.verticals.name,
    path: routes.verticals.path,
    nextRoute: () => {
      const authStore = useAuthStore();
      if (!authStore.isSignedIn()) {
        authStore.setAuthFlow(AuthFlow.Highway);
        return routes.initiation.path;
      }
      return routes.match.path;
    },
    prevRoute: () => {
      return null;
    },
    validRoute: (fromRoute: string = null) => {
      const authStore = useAuthStore();
      const isSignedIn = authStore.isSignedIn();
      const nuxtApp = useNuxtApp();

      if (isSignedIn) {
        const signedInRoute = getSignedInFlowRoute();
        if (fromRoute === signedInRoute) {
          return null;
        }

        return signedInRoute === routes.journey.path ? null : signedInRoute;
      }
      return null;
    },
  },
  {
    name: routes.initiation.name,
    path: routes.initiation.path,
    nextRoute: () => {
      const authStore = useAuthStore();
      if (authStore.isSignedIn()) {
        return routes.verticals.path;
      }
      return routes.signup.path;
    },
    prevRoute: () => {
      return routes.verticals.path;
    },
    validRoute: async () => {
      var ret = null;
      const authStore = useAuthStore();

      const verticalStore = useVerticalStore();
      if (isCurrentRouteDirectNavigated && authStore.isSignedIn()) {
        if (authStore.user.isActivated()) {
          const questionnaireStore = useQuestionnaireStore();
          var sessions = await getUserQuestionnaireSessions(
            authStore.token,
            "Initiation"
          );

          if (sessions) {
            questionnaireStore.setAllAnswers(sessions[0]);
          }
        }
        ret = getSignedInFlowRoute();
      } else if (
        authStore.isAuthFlowHighway() &&
        verticalStore.selected === null
      ) {
        ret = routes.verticals.path;
      } else if (isCurrentRouteDirectNavigated) {
        ret = routes.verticals.path;
      }
      return ret;
    },
  },
  {
    name: routes.signup.name,
    path: routes.signup.path,
    nextRoute: () => {
      const authStore = useAuthStore();
      const verticalStore = useVerticalStore();
      if (authStore.userAlreadyRegistered) {
        return getSignedInFlowRoute();
      }
      if (verticalStore.selected === null) {
        authStore.setAuthFlow(AuthFlow.Signup);
      } else {
        authStore.setAuthFlow(AuthFlow.Highway);
      }
      return routes.activate.path;
    },
    prevRoute: () => {
      return routes.initiation.path;
    },
    validRoute: (fromPath: string = null) => {
      var ret = null;
      const authStore = useAuthStore();
      if (authStore.isSignedIn()) {
        ret = getSignedInFlowRoute();
      }
      return ret;
    },
  },
  {
    name: routes.signin.name,
    path: routes.signin.path,
    nextRoute: () => {
      const authStore = useAuthStore();
      authStore.setAuthFlow(AuthFlow.Signin);
      return getSignedInFlowRoute();
    },
    prevRoute: () => {
      return null;
    },
    validRoute: (fromPath: string = null) => {
      return null;
    },
  },
  {
    name: routes.activate.name,
    path: routes.activate.path,
    nextRoute: () => {
      const authStore = useAuthStore();
      if (authStore.isAuthFlowHighway()) {
        return routes.match.path;
      }
      return routes.initiation.path;
    },
    prevRoute: () => {
      return routes.signup.path;
    },
    validRoute: (fromPath: string = null) => {
      var ret = null;
      const authStore = useAuthStore();
      if (isCurrentRouteDirectNavigated && authStore.isSignedIn()) {
        ret = getSignedInFlowRoute();
      } else if (!authStore.isSignedIn()) {
        ret = routes.verticals.path;
      } else if (!authStore.isUserVerified) {
        ret = routes.signup.path;
      }
      return ret;
    },
  },
  {
    name: routes.match.name,
    path: routes.match.path,
    nextRoute: () => {
      const nuxtApp = useNuxtApp();

      if (process.client && nuxtApp.$isDesktop()) {
        return routes.journey.path;
      }

      return routes.downloadApp.path;
    },
    prevRoute: () => {
      return routes.activate.path;
    },
    validRoute: (fromPath: string = null) => {
      var ret = null;
      const authStore = useAuthStore();
      if (isCurrentRouteDirectNavigated && authStore.isSignedIn()) {
        ret = getSignedInFlowRoute();
      } else if (!authStore.isSignedIn()) {
        ret = routes.verticals.path;
      }
      return ret;
    },
  },
  {
    name: routes.introSessionInfo.name,
    path: routes.introSessionInfo.path,
    nextRoute: () => {
      return null;
    },
    prevRoute: () => {
      return null;
    },
    validRoute: (fromPath: string = null) => {
      var ret = null;
      const authStore = useAuthStore();
      if (authStore.isSignedIn()) {
        if (fromPath != routes.match.path) {
          return getSignedInFlowRoute();
        }
      } else if (!authStore.isSignedIn()) {
        ret = routes.verticals.path;
      }
      return ret;
    },
  },
  {
    name: routes.schedule.name,
    path: routes.schedule.path,
    nextRoute: () => {
      return null;
    },
    prevRoute: () => {
      return null;
    },
    validRoute: (fromPath: string = null) => {
      var ret = null;
      const authStore = useAuthStore();
      const schedulingStore = useSchedulingStore();
      if (authStore.isSignedIn()) {
        if (
          (fromPath != routes.introSessionInfo.path &&
            fromPath != routes.messages.path) ||
          schedulingStore.clientSchedulingState?.availableTimeSlots?.length ===
            0
        ) {
          return getSignedInFlowRoute();
        }
      } else {
        ret = routes.verticals.path;
      }
      return ret;
    },
  },
  {
    name: routes.purchase.name,
    path: routes.purchase.path,
    nextRoute: () => {
      return null;
    },
    prevRoute: () => {
      return routes.downloadApp.path;
    },
    validRoute: (fromPath: string = null) => {
      var ret = null;
      const authStore = useAuthStore();

      const signedIn = authStore.isSignedIn();

      if (signedIn && isCurrentRouteDirectNavigated) {
        const route = getSignedInFlowRoute();

        ret = route === routes.journey.path ? routes.purchase.path : route;
      } else if (!signedIn) {
        ret = routes.verticals.path;
      }

      return ret;
    },
  },
  {
    name: routes.downloadApp.name,
    path: routes.downloadApp.path,
    nextRoute: () => {
      return null;
    },
    prevRoute: () => {
      return routes.match.path;
    },
    validRoute: (fromPath: string = null) => {
      var ret = null;
      const authStore = useAuthStore();

      if (isCurrentRouteDirectNavigated && authStore.isSignedIn()) {
        ret = getSignedInFlowRoute();
      } else if (!authStore.isSignedIn()) {
        ret = routes.verticals.path;
      }
      return ret;
    },
  },
  {
    name: routes.questionnaire.name,
    path: routes.questionnaire.path,
    nextRoute: () => {
      return null;
    },
    prevRoute: () => {
      return null;
    },
    validRoute: () => {
      return null;
    },
  },
  {
    name: routes.test.name,
    path: routes.test.path,
    nextRoute: () => {
      return routes.verticals.path;
    },
    prevRoute: () => {
      return null;
    },
    validRoute: () => {
      return null;
    },
  },
];

type AppFlowRoute = {
  name: string;
  path: string;
  nextRoute: () => string | null;
  prevRoute: () => string | null;
  validRoute: (fromPath: string) => string | null;
};
