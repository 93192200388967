import { User } from "~/model/user";
import { axiosInstance } from "~/plugins/axios";

export const requestNameBirthdayActivation = async (
  firstname,
  lastname,
  birthday,
  marketingConsent
): Promise<User> => {
  let params = {
    firstname: firstname,
    lastname: lastname,
    birthday: birthday,
    marketingConsent: marketingConsent,
  };
  const { data } = await axiosInstance.post(
    "/api/activation/name-birthday",
    params
  );
  return Object.assign(new User(), data);
};

export const requestCodeActivation = async (code): Promise<User> => {
  let params = {
    code: code,
  };
  const { data } = await axiosInstance.post("/api/activation/code", params);
  return Object.assign(new User(), data);
};

export const requestEnrollment = async (vertical) => {
  let params = {
    vertical: vertical,
  };
  const { data } = await axiosInstance.post(
    "/api/activation/enroll-to-vertical",
    params
  );
  return data;
};

export const startOnboarding = async (verticalEnrollments) => {
  let params = {
    verticalEnrollments: verticalEnrollments,
  };
  const { data } = await axiosInstance.post(
    "/api/activation/start-onboarding",
    params
  );
  return data;
};

export const evaluateInitiation = async (verticalEnrollment) => {
  let params = {
    verticalEnrollment: verticalEnrollment,
  };
  const { data } = await axiosInstance.post(
    "/api/activation/evaluate-initiation",
    params
  );
  return data;
};
export const updateSponsorship = async (activationCode): Promise<User> => {
  let params = {
    firstname: null,
    lastname: null,
    birthday: null,
    marketingConsent: null,
    ActivationCode: activationCode,
  };
  const { data } = await axiosInstance.post(
    "/api/activation/UpdateSponsorship",
    params
  );
  return Object.assign(new User(), data);
};
