<template>
  <div
    :class="{
      'border-[1px] rounded-xl overflow-auto': true,
      [getBorderColor()]: true,
      'w-auto': width == 0,
      [`w-[${width}px]`]: width != 0,
      'h-auto': height == 0,
      [`h-[${height}px]`]: height != 0,
      ['shadow']: !isPressed,
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    }"
    @mouseover="isHovered = true"
    @mousedown="isPressed = true"
    @mouseup="isPressed = false"
    @mouseleave="isPressed = false"
    @mouseout="isHovered = false"
    @click.stop="$emit('primaryAction')"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { MeetingCardType } from "./type";

export default {
  setup() {
    const isHovered = ref(false);
    const isPressed = ref(false);
    return { isHovered, isPressed };
  },
  props: {
    cardType: {
      type: String as () => MeetingCardType,
      default: MeetingCardType.Default,
    },
    showSideIndicator: {
      type: Boolean,
      default: true,
    },
    alwaysShowBorders: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getBorderColor() {
      if (!this.isHovered && !this.isPressed && !this.alwaysShowBorders) {
        return "";
      }
      switch (this.cardType) {
        case MeetingCardType.Default:
          return "border-primaryV2";
        case MeetingCardType.Warning:
          return "border-system-warning-base";
        case MeetingCardType.Alert:
          return "border-system-alert-base";
        case MeetingCardType.Success:
          return "border-system-success-base";
        default:
          return "";
      }
    },
    getLeftBorderColor() {
      switch (this.cardType) {
        case MeetingCardType.Default:
          return "bg-primaryV2";
        case MeetingCardType.Warning:
          return "bg-system-warning-base";
        case MeetingCardType.Alert:
          return "bg-system-alert-base";
        case MeetingCardType.Success:
          return "bg-system-success-base";
        default:
          return "";
      }
    },
  },
};
</script>
