import { axiosInstance } from "~/plugins/axios";
import ApiUtils from "~/utils/api";
import type { Message, PaginationResponse } from "~/model/messaging";

const apiPathPrefix = "/api/messagingV2";

export const getMessages = async (
  sessionId: string,
  cursor: string | null = null,
  limit: number | null = null,
  checksum: string | null = null
): Promise<PaginationResponse<Message>> => {
  const queryString = ApiUtils.toQueryString({
    sessionId: sessionId,
    cursor: cursor,
    limit: limit,
    checksum: checksum,
  });
  const data = await axiosInstance.get(
    `${apiPathPrefix}/messages${queryString}`
  );
  return data.data;
};

export const getMessage = async (
  messageId: string,
  withAttachmentUrls: boolean = false
) => {
  const queryString = ApiUtils.toQueryString({
    messageId: messageId,
    withAttachmentUrls: withAttachmentUrls,
  });
  const data = await axiosInstance.get(
    `${apiPathPrefix}/getMessage${queryString}`
  );
  return data.data;
};

export const getSessions = async (
  cursor: string | null = null,
  limit: number | null = null,
  checksum: string | null = null,
  lastMessageDate: Date | null = null
) => {
  const queryString =
    ApiUtils.toQueryString({
      lastMessageDate: lastMessageDate?.toISOString(),
      cursor: cursor,
      limit: limit,
      checksum: checksum,
    }) ?? "";
  const url = `${apiPathPrefix}/sessions${queryString}`;
  const data = await axiosInstance.get(url);
  return data.data;
};

export const markSessionAsRead = async (id: string): Promise<boolean> => {
  const queryString = ApiUtils.toQueryString({
    id: id,
  });
  const data: boolean = await axiosInstance.get(
    `${apiPathPrefix}/markAsRead${queryString}`
  );
  return data;
};

export const sendMessage = async (
  sessionId: string,
  message: Message
): Promise<Message> => {
  const queryString = ApiUtils.toQueryString({
    sessionId: sessionId,
  });
  const data = await axiosInstance.post(
    `${apiPathPrefix}/sendMessage${queryString}`,
    message
  );
  return data.data;
};

export const registerUserDevice = async (
  deviceId: string
): Promise<boolean> => {
  const queryString = ApiUtils.toQueryString({
    deviceId,
    deviceOS: deviceId,
  });
  const data = await axiosInstance.get(
    `${apiPathPrefix}/registerDevice${queryString}`
  );
  return data.data;
};
