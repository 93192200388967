import { defineNuxtPlugin } from "#app";
import type { UAParserInstance } from "ua-parser-js";

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();
  const parser = nuxtApp.$uaParser as UAParserInstance;
  const device = parser.getDevice();

  const isMobile = device.type === "mobile";
  const isTablet = device.type === "tablet";
  const isDesktop = !isMobile && !isTablet;

  return {
    provide: {
      isMobile: () => isMobile,
      isTablet: () => isTablet,
      isDesktop: () => isDesktop,
    },
  };
});
