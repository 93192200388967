import GoogleAnalytics from "./googleAnalytics";

type TrackingType = "mixpanel" | "googleAnalytics";

class Tracking {
  private static instance: Tracking;
  private mixpanel;

  private constructor() {
    this.mixpanel = useMixpanel();
  }

  public static getInstance(): Tracking {
    if (!Tracking.instance) {
      Tracking.instance = new Tracking();
    }

    return Tracking.instance;
  }

  private _trackEvent(
    event: string,
    params: any = null,
    trackingTypes: TrackingType[] = ["googleAnalytics", "mixpanel"]
  ) {
    // Track event on Google Analytics
    if (trackingTypes.includes("googleAnalytics")) {
      GoogleAnalytics.trackEvent(event, params);
    }

    // Track event on Mixpanel
    if (trackingTypes.includes("mixpanel") && typeof window !== "undefined") {
      this.mixpanel.track(event, params);
    }
  }

  private _timeEvent(
    event: string,
    params: any = null,
    trackingTypes: TrackingType[] = ["mixpanel"]
  ) {
    // Track event on Google Analytics
    if (trackingTypes.includes("googleAnalytics")) {
      GoogleAnalytics.trackEvent(event, params);
    }

    // Track event on Mixpanel
    if (trackingTypes.includes("mixpanel") && typeof window !== "undefined") {
      this.mixpanel.timeEvent(event, params);
    }
  }

  setUser(user: any) {
    // Set user on Google Analytics
    // GoogleAnalytics.setUser(user);

    // Set user on Mixpanel
    if (typeof window !== "undefined") {
      this.mixpanel.setUser(user);
    }
  }

  clearUser() {
    // Set user on Google Analytics
    // GoogleAnalytics.setUser(user);

    // Set user on Mixpanel
    if (typeof window !== "undefined") {
      this.mixpanel.clearUser();
    }
  }

  activation(action: string, params: any = null) {
    this._trackEvent(`Activation ${action}`, params);
  }

  activationCode(action: string, params: any = null) {
    this._trackEvent(`Activation Code ${action}`, params);
  }

  authentication(action: string, params: any = null) {
    this._trackEvent(`Authentication ${action}`, params);
  }

  careTeam(action: string, params: any = null) {
    this._trackEvent(`Care Team ${action}`, params);
  }

  introSessionInfo(action: string, params: any = null) {
    this._trackEvent(`Intro Session Info ${action}`, params);
  }

  landingPage(action: string, params: any = null) {
    this._trackEvent(`Landing Page ${action}`, params);
  }

  login(action: string, params: any = null) {
    this._trackEvent(`Login ${action}`, params);
  }

  loggedOut() {
    this._trackEvent(`Logged out`);
  }
  introSession(action: string, params: any = null) {
    this._trackEvent(`Intro Session ${action}`, params);
  }

  meeting(action: string, params: any = null) {
    this._trackEvent(`Meeting ${action}`, params);
  }

  meetingBannerAction(params: any = null) {
    this._trackEvent("Meeting Banner Action", params);
  }

  meetingCall(action: string, params: any = null) {
    this._trackEvent(`Meeting - ${action}`, params);
  }

  meetingCallTimeEvent(action: string, params: any = null) {
    this._timeEvent(`Meeting - ${action}`, params);
  }

  messageSent(params: any = null) {
    this._trackEvent("Message Sent", params);
  }

  messageSessionNavigated(params: any = null) {
    this._trackEvent("Message Session Navigated", params);
  }

  mentalHealthUserTypeSuggestions(action: string, params: any = null) {
    this._trackEvent(`Mental Health User Type Suggestions ${action}`, params);
  }

  navBarClicked(params: any = null) {
    this._trackEvent(`NavBar Click`, params);
  }

  offerDetailPage(action: string, params: any = null) {
    this._trackEvent(`Offer Detail Page ${action}`, params);
  }

  onboardingQuestionnare(action: string, params: any = null) {
    this._trackEvent(`Onboarding Questionnaire ${action}`, params);
  }

  professionalAddedToTeam(params: any = null) {
    this._trackEvent("Professional Added To Team", params);
  }

  professionalEnrollmentPage(params: any = null) {
    this._trackEvent("Professional Enrollment Page", params);
  }

  providerSuggested(params: any = null) {
    this._trackEvent("Provider Suggested", params);
  }

  providerSuggestionList(action: string, params: any = null) {
    this._trackEvent(`Provider Suggestion List ${action}`, params);
  }

  qrPopupSeen(params: any = null) {
    this._trackEvent("QR Pop Up Seen", params);
  }

  questionnaire(action: string, params: any = null) {
    this._trackEvent(`Questionnaire ${action}`, params);
  }

  signup(action: string, params: any = null) {
    this._trackEvent(`Signup ${action}`, params);
  }

  startCheckout(params: any = null) {
    this._trackEvent("Start Checkout", params);
  }

  startPayment(params: any = null) {
    this._trackEvent("Start Payment", params);
  }

  successfulPayment(params: any = null) {
    this._trackEvent("Successful Payment", params);
  }

  timeSlotSelection(action: string, params: any = null) {
    this._trackEvent(`Time Slot Selection ${action}`, params, ["mixpanel"]);
  }

  verticalSelected(params: any = null) {
    this._trackEvent("Vertical Selected", params);
  }

  webExperienceStarted(params: any = null) {
    this._trackEvent("Web Experience Started", params);
  }

  webInstallPageSeen(params: any = null) {
    this._trackEvent("Web Install Page Seen", params);
  }

  webTest(action: string, params: any = null) {
    this._trackEvent(`Web Test ${action}`, params);
  }
}

export default Tracking;
