import {
  IntervalOfWeek,
  TimeSlot,
  TimeSpan,
  MatchingPreference,
  DateInterval
} from "~/model/matching/preferences";

export const initialMatchingPreferences = [
  new MatchingPreference(IntervalOfWeek.weekday, [
    new TimeSlot(
      new DateInterval(new Date(2024, 0, 1, 9, 0), new TimeSpan(4)),
      true
    ),
    new TimeSlot(
      new DateInterval(new Date(2024, 0, 1, 13, 0), new TimeSpan(6)),
      true
    ),
    new TimeSlot(
      new DateInterval(new Date(2024, 0, 1, 19, 0), new TimeSpan(4)),
      true
    ),
  ]),
  new MatchingPreference(IntervalOfWeek.weekend, [
    new TimeSlot(
      new DateInterval(new Date(2024, 0, 1, 9, 0), new TimeSpan(4)),
      true
    ),
    new TimeSlot(
      new DateInterval(new Date(2024, 0, 1, 13, 0), new TimeSpan(6)),
      true
    ),
    new TimeSlot(
      new DateInterval(new Date(2024, 0, 1, 19, 0), new TimeSpan(4)),
      true
    ),
  ]),
];
