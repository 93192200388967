import { IntervalOfWeek, MatchingPreference, TimeSlot } from ".";


export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export class ClientMatchingPreferences {
    availabilityMatrix: Map<DayOfWeek, TimeSlot[]>;
    constructor(matchingPreferences: MatchingPreference[]) {
        this.availabilityMatrix = new Map<DayOfWeek, TimeSlot[]>();
        this.availabilityMatrix.set(DayOfWeek.Sunday, matchingPreferences.find(mp => mp.intervalOfWeek == IntervalOfWeek.weekend).timeSlots);
        this.availabilityMatrix.set(DayOfWeek.Monday, matchingPreferences.find(mp => mp.intervalOfWeek == IntervalOfWeek.weekday).timeSlots);
        this.availabilityMatrix.set(DayOfWeek.Tuesday, matchingPreferences.find(mp => mp.intervalOfWeek == IntervalOfWeek.weekday).timeSlots);
        this.availabilityMatrix.set(DayOfWeek.Wednesday, matchingPreferences.find(mp => mp.intervalOfWeek == IntervalOfWeek.weekday).timeSlots);
        this.availabilityMatrix.set(DayOfWeek.Thursday, matchingPreferences.find(mp => mp.intervalOfWeek == IntervalOfWeek.weekday).timeSlots);
        this.availabilityMatrix.set(DayOfWeek.Friday, matchingPreferences.find(mp => mp.intervalOfWeek == IntervalOfWeek.weekday).timeSlots);
        this.availabilityMatrix.set(DayOfWeek.Saturday, matchingPreferences.find(mp => mp.intervalOfWeek == IntervalOfWeek.weekend).timeSlots);
    }

    toJSON() {
        let obj: any = {};
        // Copy all existing properties
        for (let key in this) {
            if (this.hasOwnProperty(key) && key !== 'availabilityMatrix') {
                obj[key] = this[key];
            }
        }
        // Convert availabilityMatrix to object
        obj.availabilityMatrix = {};
        for (let [k, v] of this.availabilityMatrix) {
            obj.availabilityMatrix[k] = v;
        }
        return obj;
    }

}