import type { UserSummary } from "../user/summary";
import { DexieMessage } from "#imports";

export class Message {
  id?: string;
  sessionID?: string;
  text?: string;
  author?: string;
  sentBy?: string;
  dateSent?: string;
  dateReceived?: Date;
  isRead?: boolean;
  notificationsSent?: boolean;
  attachments?: Attachment[];
  actionLogs?: MessageActionLog[];
  status: MessageStatus;
  constructor(message: Message) {
    this.id = message.id;
    this.sessionID = message.sessionID;
    this.text = message.text;
    this.author = message.author;
    this.sentBy = message.sentBy;
    this.dateSent = message.dateSent;
    this.dateReceived = message.dateReceived;
    this.isRead = message.isRead;
    this.notificationsSent = message.notificationsSent;
    this.attachments = message.attachments;
    this.actionLogs = message.actionLogs;
    this.status = message.status;
  }
}
export class MessageGroup {
  day?: string;
  messages?: DexieMessage[];
}

export class Attachment {
  id?: string;
  type: AttachmentType;
  mediaType: string = "";
  name: string = "";
  thumbnailId?: string;
  url?: string;
  thumbnailUrl?: string;
}

export enum AttachmentType {
  Image = "Image",
  File = "File",
}

export enum MessageStatus {
  Sending,
  Sent,
  Error,
}

export class ChatSession {
  id: string = "";
  createdAt?: Date;
  participants: UserSummary[] = [];
  lastMessage?: Message;
  lastMessageDate?: Date;
  unreadMessageCount: number = 0;
  messageCount: number = 0;
  clientCategory?: ChatSessionClientCategory;
  constructor(chatSession: ChatSession) {
    this.id = chatSession.id;
    this.createdAt = chatSession.createdAt;
    this.participants = chatSession.participants;
    this.lastMessage = chatSession.lastMessage;
    this.lastMessageDate = chatSession.lastMessageDate;
    this.unreadMessageCount = chatSession.unreadMessageCount;
    this.messageCount = chatSession.messageCount;
    this.clientCategory = chatSession.clientCategory;
  }
}

export enum ChatSessionClientCategory {
  NewMatch = "NewMatch",
  GetFirstMeeting = "GetFirstMeeting",
  Active = "Active",
  Archived = "Archived",
}

export const GetCategoryFromDisplayName = (
  displayName: String
): ChatSessionClientCategory | null => {
  switch (displayName) {
    case "Yeni":
      return ChatSessionClientCategory.NewMatch;
    case "Tanışılacak":
      return ChatSessionClientCategory.GetFirstMeeting;
    case "Aktif":
      return ChatSessionClientCategory.Active;
    case "Arşiv":
      return ChatSessionClientCategory.Archived;
    default:
      return null;
  }
};

export const GetDisplayNameFromCategory = (
  category: ChatSessionClientCategory | null
): String => {
  switch (category) {
    case ChatSessionClientCategory.NewMatch:
      return "Yeni";
    case ChatSessionClientCategory.GetFirstMeeting:
      return "Tanışılacak";
    case ChatSessionClientCategory.Active:
      return "Aktif";
    case ChatSessionClientCategory.Archived:
      return "Arşiv";
    default:
      return "Tümü";
  }
};

export class PaginationResponse<T> {
  results: T[] = [];
  hasMore: boolean = false;
  cursor?: string;
}

export class MessageActionLog {
  userId: string;
  date: Date;
  action: string;
}
