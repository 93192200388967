import { Buffer } from "buffer";
import { axiosInstance } from "~/plugins/axios";
import type { AuthenticationResponse } from "~/model/auth/authenticationResponse";
import { User } from "~/model/user";
import { useAuthStore } from "~/store/auth";
import ApiUtils from "~/utils/api";

export const emailSignup = async (
  email,
  password,
  confirmedPassword
): Promise<AuthenticationResponse> => {
  let params = {
    email: email,
    password: password,
    confirmedPassword: confirmedPassword,
    registrationSource: "WebApp",
  };
  const { data } = await axiosInstance.post("/api/auth/email-signup", params);
  return data;
};

export const otpRequest = async (email) => {
  const { data } = await axiosInstance.get(`/api/auth/otp/request/${email}`);
  return data;
};

export const otpVerification = async (email, code): Promise<boolean> => {
  const verificationString = Buffer.from(email + "##" + code, "utf8").toString(
    "base64"
  );
  const queryParams = ApiUtils.toQueryString({
    verificationString: verificationString,
  });

  const { data } = await axiosInstance.post(
    `/api/auth/otp/verification/code-verification${queryParams}`,
    {}
  );
  return data;
};

export const googleSsoCallback = async (
  idToken
): Promise<AuthenticationResponse> => {
  let params = {
    idToken: idToken,
    registrationSource: "WebApp",
  };
  const { data } = await axiosInstance.post(
    "/api/auth/google-sso-callback",
    params
  );

  return data;
};

export const appleSsoCallback = async (
  idToken,
  nonce
): Promise<AuthenticationResponse> => {
  let params = {
    idToken: idToken,
    nonce: nonce,
    registrationSource: "WebApp",
  };
  const { data } = await axiosInstance.post(
    "/api/auth/apple-sso-callback",
    params
  );

  return data;
};

export const login = async (
  email: string,
  password: string
): Promise<AuthenticationResponse> => {
  let body = {
    email: email,
    password: password,
  };
  const { data } = await axiosInstance.post("/api/auth/LogIn", body);
  return data as AuthenticationResponse;
};

export const refreshAuthToken = async (
  token: string
): Promise<AuthenticationResponse> => {
  const body = {
    token,
  };
  const { data } = await axiosInstance.post("/api/auth/RefreshToken", body);
  return data as AuthenticationResponse;
};

export const logOut = async (
  refreshToken: string,
  deviceId: string
): Promise<boolean> => {
  const body = {
    refreshToken,
    deviceId,
  };
  const { data } = await axiosInstance.post("/api/auth/LogOut", body);
  return data as boolean;
};

export const getUser = async (
  doNotForceToLogout: boolean = false
): Promise<User> => {
  const body = {
    doNotForceToLogout,
  };

  const { data } = await axiosInstance.post("/api/auth/GetUser", body);
  return Object.assign(new User(), data);
};
