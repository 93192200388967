<template>

  <NuxtSnackbar>
    <template #message-inner="{ message }">
      <AppSnackbar :title="message.title" :type="message.indicatorType" :text="message.text"
        :buttonLabel="message.buttonLabel" :buttonAction="message.buttonAction" />
    </template>
  </NuxtSnackbar>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
