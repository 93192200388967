import { HeltiaBase, VerticalEnum } from "../common";
import type { Activation } from "./activation";
import type { Balance } from "./balance";
import type { CareTeamSummary } from "./careTeam";
import type { DeviceInfo } from "./deviceInfo";
import type { UserJourney } from "./journey";
import type { UserPlan } from "./plan/user";
import type { VerticalEnrollment } from "./verticalEnrollment";

export class User extends HeltiaBase {
  id?: string;
  createdAt: Date;
  firstname?: string;
  lastname?: string;
  email: string;
  title: string;
  profilePictureUrl?: string;
  chatSessions: string[];
  userDevices: DeviceInfo[];
  journey?: UserJourney;
  userType: UserType;
  userState: UserState;
  birthday?: Date;
  phone?: string;
  type?: string;
  activation?: Activation;
  enrollments: VerticalEnrollment[];
  planV2?: UserPlan;

  isSignedUp(): boolean {
    return this.userState === UserState.SignedUp;
  }
  isVerified(): boolean {
    return this.userState === UserState.Verified;
  }
  isNotSponsored(): boolean {
    return this.userState === UserState.NotSponsored;
  }
  isActivated(): boolean {
    return this.userState === UserState.Activated;
  }
  isDeactivated(): boolean {
    return this.userState === UserState.Deactivated;
  }
  isInitialVerticalSelected(): boolean {
    return this.userState === UserState.InitialVerticalSelected;
  }
  isOnboardingStarted(): boolean {
    return this.userState === UserState.OnboardingStarted;
  }
  isOnboarded(): boolean {
    return this.userState === UserState.Onboarded;
  }
  wasOnboarded(): boolean {
    return this.isOnboardingStarted() || this.isOnboarded();
  }
  isClient(): boolean {
    return this.userType === UserType.Client;
  }

  hasEnrolledInVertical(vertical: VerticalEnum): boolean {
    return this.enrollments.some(
      (enrollment) => enrollment.vertical === vertical
    );
  }
}

export class UserProfile {
  id?: string;
  careTeamSummary?: CareTeamSummary;
  balance?: Balance;
  planV2?: UserPlan;
  email?: string;
}

export enum UserType {
  Client = "Client",
  GeneralCounsel = "GeneralCounsel",
  CareNavigator = "CareNavigator",
  Coach = "Coach",
  Psychotherapist = "Psychotherapist",
  Nutritionist = "Nutritionist",
  Support = "Support",
  Physiotherapist = "Physiotherapist",
  Doula = "Doula",
  LactationCounselor = "LactationCounselor",
  ChildCounselor = "ChildCounselor",
  ParentCounselor = "ParentCounselor",
  FamilyCounselor = "FamilyCounselor",
  SportCounselor = "SportCounselor",
  PlantCareExpert = "PlantCareExpert",
  Veterinarian = "Veterinarian",
  LegalCounselor = "LegalCounselor",
  FinancialCounselor = "FinancialCounselor",
  SocialLifeCounselor = "SocialLifeCounselor",
  OfficeErgonomicsCounselor = "OfficeErgonomicsCounselor",
  ExecutiveSupport = "ExecutiveSupport",
  TechnologyCounselor = "TechnologyCounselor",
}

export enum UserState {
  SignedUp = "SignedUp",
  Verified = "Verified",
  NotSponsored = "NotSponsored",
  Activated = "Activated",
  Onboarded = "Onboarded",
  Deactivated = "Deactivated",
  Deleted = "Deleted",
  OnboardingStarted = "OnboardingStarted",
  InitialVerticalSelected = "InitialVerticalSelected",
}

export enum AuthFlow {
  Signin = "Signin",
  Signup = "Signup",
  Highway = "Highway",
}

export enum UserRole {
  Client = "Client",
  Provider = "Provider",
}
