import Dexie from "dexie";
import { ChatSession, Message } from "~/model/messaging";
export class DexieChatSession extends ChatSession {
  participantIds: string[];

  constructor(chatsession: ChatSession) {
    super(chatsession);
    this.participantIds = this.participants.map((p) => p.id);
  }
}

export const fromDexieMessage = (message: DexieMessage): Message => {
  return {
    id: message.localId.toString(),
    sessionID: message.sessionID,
    dateSent: message.dateSent,
    text: message.text,
    attachments: message.attachments,
    status: message.status,
    author: message.author,
    sentBy: message.sentBy,
    dateReceived: message.dateReceived,
    isRead: message.isRead,
    notificationsSent: message.notificationsSent,
    actionLogs: message.actionLogs,
  };
};

export class DexieMessage extends Message {
  localId?: number;
}
export class HeltiaDatabase extends Dexie {
  chatSessions!: Dexie.Table<DexieChatSession, number>;
  sessionMessages!: Dexie.Table<DexieMessage, number>;
  constructor() {
    super("HeltiaDatabase");
    this.version(2).stores({
      chatSessions: "id,lastMessageDate,*participantIds",
      sessionMessages: "id,sessionID,dateSent",
    });
  }
}
let db: HeltiaDatabase | null = null;
export const initializeDexieDatabase = async (): Promise<HeltiaDatabase> => {
  db = new HeltiaDatabase();
  if (db && db.isOpen()) {
    return db;
  }
  try {
    await db.open();
    return db;
  } catch (error) {
    console.error("Error initializing Dexie database:", error);
    throw error;
  }
};
export const clearDexieDatabase = async (): Promise<void> => {
  if (!db) {
    console.error("Error: Dexie database has not been initialized");
    return;
  }
  try {
    await db.delete();
  } catch (error) {
    console.error("Error clearing Dexie database:", error);
  }
};
