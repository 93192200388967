import type { UAParserInstance } from "ua-parser-js";

export const isNotWebView = () => {
  const nuxtApp = useNuxtApp();

  const browser = (nuxtApp.$uaParser as UAParserInstance).getBrowser();
  // Check if the user agent is one of the specified web views
  const isWebView = ["FBAN", "FBAV", "Instagram", "TikTok", "Facebook"].some(
    (webView) => browser.name.includes(webView)
  );

  return !isWebView;
};

export const getDeviceId = () => {
  const nuxtApp = useNuxtApp();

  const parser = nuxtApp.$uaParser as UAParserInstance;
  const device = parser.getDevice();
  const os = parser.getOS();

  const isMobile = device.type === "mobile";
  const isTablet = device.type === "tablet";

  let deviceType = "Desktop";
  if (isMobile) {
    deviceType = "Mobile";
  } else if (isTablet) {
    deviceType = "Tablet";
  }

  const deviceId = `Web-${deviceType}-${os.name}`.replace(/\s+/g, "");

  return deviceId;
};
