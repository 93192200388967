import { axiosInstance } from "~/plugins/axios";
import type { ProfessionalMatchingPreferences } from "~/model/common/professional/matchingPreferences";
import type { MatchingPreferenceRequest } from "~/model/matching/preferenceRequest";
import { MatchingProfessionalsRequest } from "~/model/matching/professionalRequest";
import { MatchingProfessionalsResponse } from "~/model/matching/professionalResponse";
import {
  MeetingConfiguration,
  ProviderConfiguration,
  VerticalConfiguration,
} from "~/model/matching/verticalConfiguration";
import { VerticalProfessionalMatchEvaluation } from "~/model/matching/verticalProfessionalMatchEvaluation";
import type { User } from "~/model/user";
import ApiUtils from "~/utils/api";
import type { ProfessionalProfile } from "~/model/common/professional/professionalProfile";
import type { MeetingPackageOption } from "~/model/meetingPackage";

const apiPathPrefix = "/api/user";

export const getVerticalProfessionalMatchEvaluations = async (
  vertical: string,
  assignInititationQst: boolean = true
): Promise<VerticalProfessionalMatchEvaluation[]> => {
  const queryString = ApiUtils.toQueryString({
    vertical: vertical,
    assignInititationQst: assignInititationQst,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/VerticalProfessionalMatchEvaluation${queryString}`
  );
  return data;
};

export const getVerticalConfigurations = async (
  vertical: string | null,
  userType: string | null
): Promise<VerticalConfiguration[]> => {
  const queryString = ApiUtils.toQueryString({
    vertical: vertical,
    userType: userType,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/Professionals/VerticalConfiguration${queryString}`
  );

  const ret = data.map((vertical) =>
    Object.assign(new VerticalConfiguration(), {
      ...vertical,
      providers: vertical.providers.map((provider) =>
        Object.assign(new ProviderConfiguration(), {
          ...provider,
          meetings: provider.meetings.map((meeting) =>
            Object.assign(new MeetingConfiguration(), meeting)
          ),
        })
      ),
    })
  );
  return ret;
};

export const matchingProfessionals = async (
  matchingProfessionalsRequest: MatchingProfessionalsRequest
): Promise<MatchingProfessionalsResponse> => {
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/Professionals/MatchingProfessionals`,
    matchingProfessionalsRequest
  );
  return data;
};

export const assignProfessional = async (id: string): Promise<any> => {
  let body = {
    id: id,
  };
  const { data } = await axiosInstance.post(
    "/api/user/Professionals/assign-professional",
    body
  );
  return data;
};

export const getUserProfile = async (): Promise<User> => {
  const { data } = await axiosInstance.get(`${apiPathPrefix}/GetUserProfile`);
  return data as User;
};

export const getMeetingPackageOptions = async (): Promise<
  MeetingPackageOption[]
> => {
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/MeetingPackageOptions`
  );
  return data;
};

export const updateMatchingPreference = async (
  request: MatchingPreferenceRequest
): Promise<ProfessionalMatchingPreferences> => {
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/Professionals/MatchingPreference`,
    request
  );
  return data;
};

export const getProfessionalById = async (
  id: string
): Promise<ProfessionalProfile> => {
  const { data } = await axiosInstance.get(`/api/user/Professionals/${id}`);
  return data as ProfessionalProfile;
};

export const forgotPassword = async (
  email: string
): Promise<VerticalConfiguration[]> => {
  const queryString = ApiUtils.toQueryString({
    userEmail: email,
  });
  const { data } = await axiosInstance.get(
    `${apiPathPrefix}/forgot-password/${queryString}`
  );
  return data;
};
