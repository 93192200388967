<template>
  <button
    :class="{
      'primary-small-button': true,
      'primary-small-button-alert': !!alert,
    }"
    @click="onClick"
  >
    <div class="flex gap-2 justify-between items-center">
      <span
        v-if="icon != null"
        :class="{
          'icon text-[14px]': true,
          [icon]: true,
        }"
      >
      </span>
      <span v-if="label != null" class="l3">{{ label }}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    onClick: {
      type: Function,
      required: false,
    },
    alert: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped>
.primary-small-button {
  @apply px-3 h-6 bg-primaryV2 text-surface-10 rounded-full cursor-pointer flex justify-center items-center;
}

.primary-small-button * {
  @apply text-surface-10;
}

.primary-small-button:hover {
  @apply bg-primary-60;
}

.primary-small-button:active {
  @apply bg-primary-base;
}

.primary-small-button:disabled {
  @apply bg-neutral-20 text-neutral-40 cursor-not-allowed;
}

.primary-small-button-alert {
  @apply bg-system-alert-base;
}

.primary-small-button-alert:hover {
  @apply bg-system-alert-60;
}
</style>
