// plugins/applicationinsights.client.ts
import { defineNuxtPlugin } from "#app";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export default defineNuxtPlugin(() => {
  if (useRuntimeConfig().public.env !== "local") {
    const appInsights = new ApplicationInsights({
      config: {
        disableTelemetry: false,
        connectionString:
          useRuntimeConfig().public.applicationInsightsConnectionString,
        disableAjaxTracking: true,
        disableExceptionTracking: false,
      },
    });

    appInsights.loadAppInsights();

    // Custom telemetry initializer to filter out everything except exceptions
    appInsights.addTelemetryInitializer((envelope) => {
      if (envelope.baseType !== "ExceptionData") {
        return false; // Filter out telemetry other than exceptions
      }
    });

    return {
      provide: {
        appInsights: appInsights,
      },
    };
  }
});
