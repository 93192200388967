export const eliminateTurkishCharacters = (s: String) => {
  return s
    .toLocaleLowerCase()
    .replace(/ğ/g, "g")
    .replace(/ü/g, "u")
    .replace(/ş/g, "s")
    .replace(/Ş/g, "S")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ç/g, "c");
};

export const getInitials = (values: String[]) => {
  return values
    .map((t) => t[0])
    .join("")
    .toLocaleUpperCase();
};
