
export const postAnswers = async (token, sessionId, answers) => {
    let params = {
        token: token,
        sessionId: sessionId,
        answers: answers,
    };
    const data = await $fetch("/api/questionnaire/answer-questions", {
        method: "POST",
        body: JSON.stringify(params),
    });
    return data;
};


export const getUserQuestionnaireSessions = async (
    token,
    questionnaireName
) => {
    let params = {
        token: token,
        questionnaireName: questionnaireName,
    };
    const data = await $fetch("/api/questionnaire/sessions", {
        method: "POST",
        body: JSON.stringify(params),
    });
    return data;
};


export const getTests = async (clinicalCategory, listingCategorySlug) => {
    let params = {
        clinicalCategory: clinicalCategory,
        listingCategorySlug: listingCategorySlug,
    };

    const data = await $fetch("/api/questionnaire/list", {
        params: params,
    });
    return data;
};

export const getTest = async (id) => {
    const data = await $fetch(`/api/questionnaire/getById/${id}`);
    return data;
};

export const evaluateInitiation = async (
    token,
    vertical
) => {
    let params = {
        token: token,
        vertical: vertical,
    };
    const data = await $fetch("/api/questionnaire/evaluate-initiation", {
        method: "POST",
        body: JSON.stringify(params),
    });
    return data;
};
