import { defineStore } from "pinia";
import { useVerticalStore } from "~/store/vertical-selection";
import { useAuthStore } from "~/store/auth";
import { useQuestionnaireStore } from "~/store/questionnaire";
import { assignProfessional } from "~/helpers/api/journey";
import {
  getVerticalProfessionalMatchEvaluations,
  getVerticalConfigurations,
  matchingProfessionals,
} from "~/helpers/api/user";
import { MatchingPreference, TimeSlot } from "~/model/matching/preferences";
import { QuestionnaireState, QuestionnaireStates } from "~/model/matching";
import { VerticalConfiguration } from "~/model/matching/verticalConfiguration";
import { VerticalProfessionalMatchEvaluation } from "~/model/matching/verticalProfessionalMatchEvaluation";
import { Loading, Error } from "~/model/common";
import { initialMatchingPreferences } from "~/data/matchingPreferences";
import { MatchingProfessionalsRequest } from "~/model/matching/professionalRequest";
import { ClientMatchingPreferences } from "~/model/matching/preferences/client";
import { ProfessionalProfile } from "~/model/common/professional/professionalProfile";
import type { UserType } from "~/model/user";
import Tracking from "~/utils/tracking";
import { useUserStore } from "../user";

export enum MatchingViewStates {
  Initial = "Initial",
  InitiationQuestionnaireReady = "InitiationQuestionnaireReady",
  MatchingPreferences = "MatchingPreferences",
  ProviderTypeSelection = "ProviderTypeSelection",
  MatchingProfessionalList = "MatchingProfessionalList",
  ProfessionalDetail = "ProfessionalDetail",
  MatchingSuccess = "MatchingSuccess",
}

export const useMatchingStore = defineStore("matching", {
  state: () => ({
    _loading: new Loading("Yükleniyor", "Sizi biraz bekletiyoruz...", false),
    _errors: [] as Error[],
    _matchingPreferences: initialMatchingPreferences,
    _selectedProviderUserType: null as UserType | null,
    _matchingProfessionals: [] as ProfessionalProfile[],
    _displayingProfessionalId: null as string,
    _selectedProfessionalId: null as string,
    _verticalConfiguration: null as VerticalConfiguration | null,
    _verticalConfigurations: [] as VerticalConfiguration[],
    _view: MatchingViewStates.Initial,
    _verticalProfessionalMatchEvaluations:
      [] as VerticalProfessionalMatchEvaluation[],
    _verticalProfessionalMatchEvaluation:
      null as VerticalProfessionalMatchEvaluation | null,
    _questionnaireStates: [] as QuestionnaireState[],
  }),
  getters: {
    loading(): Loading | undefined {
      return this._loading.show ? this._loading : undefined;
    },
    errors(): Error[] {
      return this._errors as Error[];
    },
    view(): MatchingViewStates {
      return this._view;
    },
    currentQuestionnaireState(): QuestionnaireState | undefined {
      return (this._questionnaireStates as QuestionnaireState[]).find(
        (qs) => qs.state != QuestionnaireStates.Completed
      );
    },
    verticalConfiguration(): VerticalConfiguration | null {
      return this._verticalConfiguration;
    },
    verticalConfigurations(): VerticalConfiguration[] | null {
      return this._verticalConfigurations;
    },
    getVerticalConfigurationOfUserType: (state) => (userType: UserType) => {
      return state._verticalConfigurations.flatMap((vertical) =>
        (vertical.providers || []).filter((p) => p.userType === userType)
      )?.[0];
    },
    getFirstMeetingTypeOfVericalConfiguration:
      (state) => (userType: UserType) => {
        const verticalConfOfUserType = state._verticalConfigurations.flatMap(
          (vertical) =>
            (vertical.providers || []).filter((p) => p.userType === userType)
        );

        return verticalConfOfUserType?.[0]?.meetings?.[0]?.meetingType;
      },
    matchingProfessionals(): ProfessionalProfile[] {
      return this._matchingProfessionals.slice(0, 3);
    },
    isMatchingProfessionalsEmpty(): boolean {
      return this._matchingProfessionals.length == 0;
    },
    displayingProfessional(): ProfessionalProfile | null {
      return this._matchingProfessionals.find(
        (professional) => professional.id == this._displayingProfessionalId
      );
    },
    selectedProviderUserType(): UserType | null {
      return this._selectedProviderUserType;
    },
    matchingPreferenceSelected(): boolean {
      return this._matchingPreferences.some((preference) =>
        preference.timeSlots.some((timeSlot) => timeSlot.isAvailable)
      );
    },
  },
  actions: {
    setViewState(viewState: MatchingViewStates) {
      this._view = viewState;
    },
    async getVerticalConfigurations(): Promise<VerticalConfiguration[]> {
      if (this._verticalConfigurations?.length == 0) {
        await this.loadVerticalConfigurations();
      }
      return this._verticalConfigurations;
    },
    async loadVerticalConfigurations() {
      var configurationResults = await getVerticalConfigurations(null, null);
      if (configurationResults == null) {
        this._errors.push(
          new Error("Bir hata oluştu.", "getVerticalConfiguration")
        );
        return;
      }

      this._verticalConfigurations = configurationResults;
    },
    async enroll() {
      this.$reset();

      const verticalStore = useVerticalStore();
      const selectedVertical = verticalStore.selectedEnumValue;
      var evaluationResult = await getVerticalProfessionalMatchEvaluations(
        selectedVertical
      );

      if (evaluationResult == null) {
        this._errors.push(
          new Error(
            "Bir hata oluştu.",
            "getVerticalProfessionalMatchEvaluation"
          )
        );
        return;
      }

      const tracking = Tracking.getInstance();
      tracking.professionalEnrollmentPage({
        EnrollmentVertical: selectedVertical,
      });

      this._verticalProfessionalMatchEvaluations = evaluationResult;
      this._verticalProfessionalMatchEvaluation = evaluationResult.find(
        (evaluation) => evaluation.vertical == selectedVertical
      );

      this._questionnaireStates =
        this._verticalProfessionalMatchEvaluation.requiredQuestionnaireNames.map(
          (questionnaireName: string) =>
            new QuestionnaireState(
              questionnaireName,
              QuestionnaireStates.Initial
            )
        );

      var { vertical, suggestedUserTypes } = this
        ._verticalProfessionalMatchEvaluation as VerticalProfessionalMatchEvaluation;

      await this.getVerticalConfigurations();
      if (suggestedUserTypes.length > 0) {
        this._verticalConfiguration = this._verticalConfigurations.find(
          (configuration) =>
            configuration.vertical == selectedVertical &&
            configuration.providers.some(
              (provider) => provider.userType == suggestedUserTypes[0]
            )
        );
      } else {
        this._verticalConfiguration = this._verticalConfigurations.find(
          (configuration) => configuration.vertical == selectedVertical
        );
      }

      this.defineViewState();
    },
    async defineViewState() {
      if (this._verticalConfigurations.length == 0) {
        this._view = MatchingViewStates.Initial;
        return;
      }

      if (this.currentQuestionnaireState != null) {
        var questionnaireName = this.currentQuestionnaireState.name;
        var questionnaireStore = useQuestionnaireStore();

        await questionnaireStore.initializeQuestionnaire(questionnaireName);

        if (questionnaireStore.questionnaire == null) {
          this._errors.push(
            new Error("Bir hata oluştu.", "initializeQuestionnaire")
          );

          this._view = MatchingViewStates.Initial;
          return;
        }

        this._view = MatchingViewStates.InitiationQuestionnaireReady;
        return;
      }

      this.defineViewStateByConfiguration();
    },
    defineViewStateByConfiguration() {
      if (this.verticalConfiguration.providers.length > 1) {
        if (this._selectedProviderUserType == null) {
          this._view = MatchingViewStates.ProviderTypeSelection;
        } else {
          this._view = MatchingViewStates.MatchingPreferences;
        }
      } else if (
        this._verticalConfiguration.providers.length == 1 ||
        this._selectedProviderUserType != null
      ) {
        this._selectedProviderUserType =
          this._verticalConfiguration.providers[0].userType;
        this._view = MatchingViewStates.MatchingPreferences;
      }
    },
    async completeQuestionnare() {
      this.currentQuestionnaireState.state = QuestionnaireStates.Completed;
      await this.defineViewState();
    },
    async selectProviderType(providerType: UserType) {
      this._selectedProviderUserType = providerType;
      await this.defineViewState();
    },
    async getPotentialProfessionals() {
      const verticalStore = useVerticalStore();
      const authStore = useAuthStore();
      const preferencesList = this._matchingPreferences.map(
        (preference: MatchingPreference) => {
          return preference.timeSlots
            .filter((timeSlot: TimeSlot) => timeSlot.isAvailable)
            .map((timeSlot: TimeSlot) => {
              return `${preference.intervalOfWeek}: ${timeSlot.interval.offset
                .getHours()
                .toString()
                .padStart(2, "0")}:00`;
            });
        }
      );

      const tracking = Tracking.getInstance();
      tracking.timeSlotSelection("Selected", {
        Vertical: verticalStore.selectedEnumValue,
        UserType: this._selectedProviderUserType,
        SelectionList: preferencesList,
      });

      var matchingProfessionalsRequest = new MatchingProfessionalsRequest(
        verticalStore.selectedEnumValue,
        this._selectedProviderUserType as UserType,
        new ClientMatchingPreferences(this._matchingPreferences)
      );

      var matchingProfessionalsResponse = await matchingProfessionals(
        matchingProfessionalsRequest
      );

      this._matchingProfessionals = matchingProfessionalsResponse.professionals;

      for (let i = 0; i < this._matchingProfessionals.length; i++) {
        const professional = this._matchingProfessionals[i];

        tracking.providerSuggested({
          ClientEmail: authStore.user.email,
          ProviderEmail: professional.email,
          ProviderType: professional.userType,
          Rank: i.toString(),
          Experience: professional.experience,
          MatchedContexts: professional.sharedContexts?.getAllContexts,
          ClientSchedulingEnabled:
            professional.matchingPreferences.clientSchedulingEnabled.toString(),
        });
      }

      tracking.providerSuggestionList("View", {
        Vertical: verticalStore.selectedEnumValue,
        UserType: this._selectedProviderUserType,
        SelectionList: preferencesList,
        SuggestedProviders: matchingProfessionalsResponse.professionals.map(
          (e) => e.id
        ),
        "Available Provider Count":
          matchingProfessionalsResponse.professionals.length.toString(),
      });

      this._view = MatchingViewStates.MatchingProfessionalList;
    },
    setDisplayingProfessionalId(id: string) {
      this._displayingProfessionalId = id;
      this._view = id
        ? MatchingViewStates.ProfessionalDetail
        : MatchingViewStates.MatchingProfessionalList;
    },
    async setSelectedProfessionalId(id: string) {
      await assignProfessional(id);
      this._selectedProfessionalId = id;
      this._view = MatchingViewStates.MatchingSuccess;
    },
    clearMatchingStore() {
      const addedProvider = this.matchingProfessionals.find(
        (_) => _.id == this._selectedProfessionalId
      );

      const existingVerticalConfigurations = this._verticalConfigurations;
      const userStore = useUserStore();
      userStore.setProfessionalProfile(addedProvider);
      this.$reset();
      this._verticalConfigurations = existingVerticalConfigurations;
    },
  },
});
