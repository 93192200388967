import { VerticalEnrollmentState } from "~/model/common";
import { User, UserRole, UserState, UserType } from "~/model/user";
import { useAuthStore } from "~/store/auth";
import { useVerticalStore } from "~/store/vertical-selection";

export type Route = {
  name: string;
  alternativeName?: string;
  path: string;
  userRoles: UserRole[];
  visibleOnMobile: boolean;
};

export type RouteKey =
  | "activate"
  | "availableTime"
  | "downloadApp"
  | "initiation"
  | "introSessionInfo"
  | "invoices"
  | "journey"
  | "match"
  | "messages"
  | "myTeam"
  | "onlineMeeting"
  | "pastAppointments"
  | "profile"
  | "professionalProfile"
  | "purchase"
  | "questionnaire"
  | "schedule"
  | "settings"
  | "signin"
  | "signup"
  | "test"
  | "verticals";

export const routes: Record<RouteKey, Route> = {
  activate: {
    name: "app-etkinlestir",
    path: "/app/etkinlestir/",
    userRoles: [],
    visibleOnMobile: true,
  },
  availableTime: {
    name: "app-uygun-saatlerim",
    path: "/app/uygun-saatlerim/",
    userRoles: [UserRole.Provider],
    visibleOnMobile: false,
  },
  downloadApp: {
    name: "app-indir",
    path: "/app/indir/",
    userRoles: [],
    visibleOnMobile: true,
  },
  invoices: {
    name: "app-faturalar",
    path: "/app/faturalar/",
    userRoles: [UserRole.Provider],
    visibleOnMobile: false,
  },
  initiation: {
    name: "app-seni-taniyalim",
    path: "/app/seni-taniyalim/",
    userRoles: [],
    visibleOnMobile: true,
  },
  journey: {
    name: "app-yolculugum",
    path: "/app/yolculugum/",
    userRoles: [UserRole.Client, UserRole.Provider],
    visibleOnMobile: false,
  },
  match: {
    name: "app-uzmanla-esles",
    path: "/app/uzmanla-esles/",
    userRoles: [],
    visibleOnMobile: true,
  },
  messages: {
    name: "app-mesajlarim",
    path: "/app/mesajlarim/",
    userRoles: [UserRole.Client, UserRole.Provider],
    visibleOnMobile: false,
  },
  myTeam: {
    name: "app-danisman-ekibim",
    path: "/app/danisman-ekibim/",
    userRoles: [UserRole.Client],
    visibleOnMobile: false,
  },
  introSessionInfo: {
    name: "app-on-gorusme",
    path: "/app/on-gorusme/",
    userRoles: [UserRole.Client],
    visibleOnMobile: true,
  },
  onlineMeeting: {
    name: "app-online-gorusme",
    alternativeName: "app-online-gorusme-id",
    path: "/app/online-gorusme/",
    userRoles: [UserRole.Client, UserRole.Provider],
    visibleOnMobile: false,
  },
  pastAppointments: {
    name: "app-randevu-gecmisim",
    path: "/app/randevu-gecmisim/",
    userRoles: [UserRole.Client, UserRole.Provider],
    visibleOnMobile: false,
  },
  professionalProfile: {
    name: "app-uzman-id",
    path: "/app/uzman/[id]",
    userRoles: [UserRole.Client],
    visibleOnMobile: false,
  },
  profile: {
    name: "app-profilim",
    path: "/app/profilim/",
    userRoles: [UserRole.Client],
    visibleOnMobile: false,
  },
  purchase: {
    name: "app-satin-al",
    path: "/app/satin-al/",
    userRoles: [],
    visibleOnMobile: true,
  },
  questionnaire: {
    name: "app-sorular-slug",
    path: "/app/sorular/[slug]",
    userRoles: [],
    visibleOnMobile: true,
  },
  schedule: {
    name: "app-randevu-ayarla",
    path: "/app/randevu-ayarla/",
    userRoles: [UserRole.Client],
    visibleOnMobile: true,
  },
  settings: {
    name: "app-ayarlarim",
    path: "/app/ayarlarim/",
    userRoles: [UserRole.Client, UserRole.Provider],
    visibleOnMobile: false,
  },
  signin: {
    name: "app-giris",
    path: "/app/giris/",
    userRoles: [],
    visibleOnMobile: true,
  },
  signup: {
    name: "app-kayit-ol",
    path: "/app/kayit-ol/",
    userRoles: [],
    visibleOnMobile: true,
  },
  test: {
    name: "testler-slug-id",
    path: "/testler/[slug]/[id]",
    userRoles: [],
    visibleOnMobile: true,
  },
  verticals: {
    name: "app-alan-secimi",
    path: "/app/alan-secimi/",
    userRoles: [],
    visibleOnMobile: true,
  },
};

export const getSignedInFlowRoute = () => {
  const authStore = useAuthStore();
  const verticalStore = useVerticalStore();
  const user = authStore.user;
  const nuxtApp = useNuxtApp();
  const isMobile = process.client && nuxtApp.$isMobile();

  if (user.isSignedUp()) {
    authStore.viewState = "otp";
    return routes.signup.path;
  } else if (user.isVerified() || user.isNotSponsored()) {
    return routes.activate.path;
  } else if (user.isActivated()) {
    return routes.initiation.path;
  } else if (user.isInitialVerticalSelected()) {
    if (user.enrollments && user.enrollments.length > 0) {
      let enrollment = user.enrollments.find(
        (e) => e.state !== VerticalEnrollmentState.MatchedWithProfessional
      );
      verticalStore.selectVertical({
        enumValue: enrollment.vertical.toString(),
      });
      return routes.match.path;
    }
    return routes.initiation.path;
  } else if (user.wasOnboarded()) {
    return isMobile ? routes.downloadApp.path : routes.journey.path;
  } else if (user.isDeactivated()) {
    return user.userType === UserType.Client ? "/" : routes.journey.path;
  }
};
