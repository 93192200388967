import { HeltiaBase } from "~/model/common";
export enum IntervalOfWeek {
  weekday = "Weekday",
  weekend = "Weekend",
}
export class TimeSlot extends HeltiaBase {
  interval: DateInterval;
  isAvailable: boolean = true;

  constructor(interval: DateInterval, isAvailable: boolean) {
    super();
    this.interval = interval;
    this.isAvailable = isAvailable;
  }
}

export class DateInterval extends HeltiaBase {
  offset: Date;
  duration: TimeSpan;

  constructor(offset: Date, duration: TimeSpan) {
    super();
    this.offset = offset;
    this.duration = duration;
  }
}

export class TimeSpan extends HeltiaBase {
  constructor(
    public hours: number = 0,
    public minutes: number = 0,
    public seconds: number = 0
  ) {
    super();
  }

  toJSON(): string {
    return `${this.hours.toString().padStart(2, "0")}:${this.minutes
      .toString()
      .padStart(2, "0")}:${this.seconds.toString().padStart(2, "0")}`;
  }
}
export class MatchingPreference extends HeltiaBase {
  intervalOfWeek: IntervalOfWeek;
  timeSlots: TimeSlot[];

  constructor(intervalOfWeek: IntervalOfWeek, timeSlots: TimeSlot[]) {
    super();
    this.intervalOfWeek = intervalOfWeek;
    this.timeSlots = timeSlots;
  }
}
