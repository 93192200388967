import { defineStore } from "pinia";
import {
  ChatSessionClientCategory,
  MessageGroup,
  MessageStatus,
} from "~/model/messaging";
import { GetCategoryFromDisplayName } from "~/model/messaging";
import { useAuthStore } from "../auth";
import { eliminateTurkishCharacters } from "~/helpers/utils/stringExtensions";
import {
  getMessages,
  getSessions,
  sendMessage,
  markSessionAsRead,
  getMessage,
} from "~/helpers/api/messaging";
import { Md5 } from "ts-md5";
import Tracking from "~/utils/tracking";
import { UserType } from "~/model/user";
import { isAfter, parseISO } from "date-fns";
import { DexieMessage, DexieChatSession } from "~/utils/dexie";
import Dexie from "dexie";
import { v4 as uuidv4 } from "uuid";

const categoryMap = new Map([
  [ChatSessionClientCategory.NewMatch, "Yeni"],
  [ChatSessionClientCategory.GetFirstMeeting, "Tanışılacak"],
  [ChatSessionClientCategory.Active, "Aktif"],
  [ChatSessionClientCategory.Archived, "Arşiv"],
]);

export const useMessagingStore = defineStore("messaging", {
  state: () => initialState(),
  getters: {
    selectedCategorySessions() {
      const category = GetCategoryFromDisplayName(this.selectedCategory);
      let sessions: DexieChatSession[] =
        this.sessionsByViewer[this.viewingUserId];
      if (category) {
        sessions = this.sessionsByViewer[this.viewingUserId].filter(
          (m) => m.clientCategory === category
        );
      }
      if (this.searchQuery.length > 0) {
        sessions = sessions.filter((s: DexieChatSession) =>
          eliminateTurkishCharacters(
            s.participants.find((p) => p.id != this.viewingUserId).firstname +
              " " +
              s.participants.find((p) => p.id != this.viewingUserId).lastname
          ).includes(eliminateTurkishCharacters(this.searchQuery))
        );
      }
      return sessions;
    },
    selectChatSessionIdByProviderId: (state) => (id: string) => {
      const session = state?.sessionsByViewer[state.viewingUserId].find((s) =>
        s.participants.some((p) => p.id === id)
      );
      return session?.id;
    },
    unreadSessionCount: (state) => {
      if (state.sessionsByViewer[state.viewingUserId]) {
        var count = state.sessionsByViewer[state.viewingUserId].filter(
          (_) => _.unreadMessageCount > 0
        ).length;
        return count;
      }
      return 0;
    },
    groupedMessages: (state) => {
      if (!state.selectedSession) {
        return [];
      }

      if (
        state.messages[state.viewingUserId][state.selectedSession.id] &&
        state.messages[state.viewingUserId][state.selectedSession.id].length > 0
      ) {
        const groups = {};
        state.messages[state.viewingUserId][state.selectedSession.id]
          .sort(
            (a, b) =>
              new Date(a.dateSent).getTime() - new Date(b.dateSent).getTime()
          )
          .forEach((message) => {
            const day = new Date(message.dateSent).toISOString().split("T")[0];
            if (!groups[day]) {
              groups[day] = [];
            }
            groups[day].push(message);
          });
        return Object.keys(groups).map((day) => ({
          day,
          messages: groups[day],
        })) as MessageGroup[];
      }
      return [];
    },
    messageSyncPaginationLimit: () => {
      return 50;
    },
    selectedSessionCounterParty: (state) => {
      if (state.selectedSession) {
        return state.selectedSession.participants.find(
          (p) => p.id != state.viewingUserId
        );
      }
      return null;
    },
    sessions: (state) => {
      return state.sessionsByViewer[state.viewingUserId];
    },
    selectedSessionMessages: (state) => {
      if (!state.selectedSession) {
        return [];
      }
      return state.messages[state.viewingUserId][state.selectedSession.id];
    },
    getChatSessionIdByUserId: (state) => (userId: string) => {
      const session = state.sessionsByViewer[state.viewingUserId].find((s) =>
        s.participantIds.includes(userId)
      );
      return session?.id;
    },
  },
  actions: {
    async setActiveSession(session: DexieChatSession) {
      this.selectedSession = session;
      await this.loadLocalMessages(session.id);
      this.fetchMessagesFromAPI(session.id);
    },
    async setActiveSessionFromUrl(sessionId: string) {
      const session = this.sessionsByViewer[this.viewingUserId].find(
        (s) => s.id === sessionId
      );
      if (session) {
        try {
          this.selectedSession = session;
          await this.loadLocalMessages(session.id);
          await this.fetchMessagesFromAPI(session.id);

          const currentUser = session.participants.find(
            (_) => _.id === this.viewingUserId
          );

          const tracking = Tracking.getInstance();
          if (currentUser.userType === UserType.Client) {
            this.selectedCategory = "";
          } else {
            this.selectedCategory =
              categoryMap.get(session.clientCategory) || "";
          }

          tracking.messageSessionNavigated({
            RelatedUser: session.participants.find(
              (i) => i.id != this.viewingUserId
            ).email,
            RelatedSession: sessionId,
            ProviderType: session.participants.find(
              (_) => _.userType !== UserType.Client
            ).userType,
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    clearSelectedSession() {
      if (this.selectedSession) {
        this.selectedSession = null;
      }
    },
    async initiate() {
      if (this.initializationStatus !== "initial") return;
      const authStore = useAuthStore();
      if (authStore.token != null) {
        if (authStore.user == null) {
          await authStore.loadUser();
        }
        if (authStore.user != null) {
          this.initializationStatus = "inProgress";
          const initialized = await this.initializeStore();
          if (initialized) {
            watch(
              () => authStore.user,
              async (newUser, oldUser) => {
                if (newUser) {
                  if (!oldUser) {
                    await this.logout();
                  }
                  if (newUser?.id !== oldUser?.id) {
                    this.initializeStore();
                  } else {
                    this.refreshSessions();
                  }
                } else {
                  await this.logout();
                }
              }
            );

            watch(
              () => authStore.SignalR?.connection?.state,
              async (newState, oldState) => {
                if (newState === "Connected") {
                  this.addressNotifications(authStore.SignalR);
                }
              }
            );
          }
        }
      } else {
        return;
      }
    },
    async initializeStore() {
      const authStore = useAuthStore();
      if (authStore.user) {
        this.viewingUserId = authStore.user.id;
        if (!this.unsentMessages[this.viewingUserId]) {
          this.unsentMessages[this.viewingUserId] = {};
        }
        if (!this.messages[this.viewingUserId]) {
          this.messages[this.viewingUserId] = {};
        }
        if (!this.sessionsByViewer[this.viewingUserId]) {
          this.sessionsByViewer[this.viewingUserId] = [];
        }
        await this.refreshSessions();
        if (authStore.user?.isClient()) {
          this.selectedCategory = "";
        } else {
          this.selectedCategory = "";
          for (let [clientCategory, categoryName] of categoryMap) {
            if (
              this.sessions.find((s) => s.clientCategory === clientCategory)
            ) {
              this.selectedCategory = categoryName;
              break;
            }
          }
        }
        this.addressNotifications(authStore.SignalR);
        this.initializationStatus = "initialized";
        return true;
      } else {
        return false;
      }
    },
    async loadLocalSessions() {
      const db = await initializeDexieDatabase();
      const dexieChatSessions = await db.chatSessions
        .where("participantIds")
        .equals(this.viewingUserId)
        .reverse()
        .sortBy("lastMessageDate");
      this.sessionsByViewer[this.viewingUserId] = dexieChatSessions || [];
      return this.sessionsByViewer[this.viewingUserId];
    },
    async sendMessage() {
      const authStore = useAuthStore();
      const isoString = new Date().toISOString();
      let newMessage = {
        text: this.unsentMessages[this.viewingUserId][
          this.selectedSession.id
        ].trim(),
        id: uuidv4(),
        dateSent: isoString,
        dateReceived: parseISO(isoString),
        sentBy: this.viewingUserId,
        sessionID: this.selectedSession.id,
        author: authStore.user!.id,
        status: MessageStatus.Sending,
      } as DexieMessage;
      this.unsentMessages[this.viewingUserId][this.selectedSession.id] = "";
      await this.onMessageReceived(newMessage);
      try {
        const data = await sendMessage(this.selectedSession.id, newMessage);
        if (data) {
          newMessage = data;
          newMessage.status = MessageStatus.Sent;
        } else {
          newMessage.status = MessageStatus.Error;
        }
      } catch (e) {
        newMessage.status = MessageStatus.Error;
      }
      const db = await initializeDexieDatabase();
      await db.sessionMessages
        .where("dateSent")
        .equals(parseISO(newMessage.dateSent).toISOString())
        .modify(newMessage);

      //TODO:Attachment hali yok
      const tracking = Tracking.getInstance();
      tracking.messageSent({
        RelatedUser: this.selectedSession.participants.find(
          (i) => i.id != this.viewingUserId
        ).email,
        RelatedSession: this.selectedSession.id,
        ProviderType: this.selectedSession.participants.find(
          (_) => _.userType !== UserType.Client
        ).userType,
      });
    },
    setSelectedCategory(category: string) {
      this.selectedCategory = category;
    },
    getCategoryUnreads(categoryDisplayName: String) {
      const category = GetCategoryFromDisplayName(categoryDisplayName);
      let sessions = this.sessionsByViewer[this.viewingUserId];
      if (category) {
        sessions = this.sessionsByViewer[this.viewingUserId].filter(
          (m) => m.clientCategory === category
        );
      }
      return sessions.filter((s) => s.unreadMessageCount > 0).length;
    },
    async addressNotifications(signalR: any) {
      if (signalR) {
        signalR.on("ChatSessionUpdated", this.onChatSessionUpdated.bind(this));
        signalR.on("UserUpdated", this.onUserUpdated.bind(this));
        signalR.on("ChatMessageReceived", this.onMessageReceived.bind(this));
      }
    },
    async onChatSessionUpdated(chatSession) {
      await this.fetchSessionsFromAPI(null);
      if (this.selectedSession && this.selectedSession.id === chatSession.id) {
        await this.fetchMessagesFromAPI(chatSession.id);
      }
    },
    async onUserUpdated(user) {
      await this.fetchSessionsFromAPI(null);
    },
    async onMessageReceived(message: DexieMessage) {
      const db = await initializeDexieDatabase();
      if (message.sessionID == null) return;
      if (message.author != this.viewingUserId) {
        message.status = MessageStatus.Sent;
      }
      const affectedSession = this.sessionsByViewer[this.viewingUserId].find(
        (s) => s.id === message.sessionID
      );
      if (affectedSession == null) {
        await this.fetchMessagesFromAPI(message.sessionID!);
        return;
      }
      if (
        affectedSession.lastMessageDate == null ||
        isAfter(message.dateSent, affectedSession.lastMessageDate)
      ) {
        affectedSession.lastMessageDate = parseISO(message.dateSent);
        affectedSession.lastMessage = message;
        if (message.author != this.viewingUserId) {
          affectedSession.unreadMessageCount++;
        }
        this.sessionsByViewer[this.viewingUserId][
          this.sessionsByViewer[this.viewingUserId].findIndex(
            (s) => s.id == affectedSession.id
          )
        ] = affectedSession;
        const newAffectedSession = JSON.parse(JSON.stringify(affectedSession));
        this.sortLocalSessions(this.sessionsByViewer[this.viewingUserId]);

        await db.chatSessions
          .where("id")
          .equals(affectedSession.id)
          .modify(newAffectedSession);
      }
      const messageString = JSON.stringify(message);
      const newMessage = JSON.parse(messageString);
      await db.sessionMessages.put(newMessage).then(() => {
        this.messages[this.viewingUserId][message.sessionID].unshift(message);
      });
      this.persistLocalMessages(message.sessionID, [message]);
    },
    async markSessionAsRead(sessionId: string) {
      if (
        this.sessionsByViewer[this.viewingUserId].find((s) => s.id == sessionId)
          .unreadMessageCount > 0
      ) {
        this.sessionsByViewer[this.viewingUserId].find(
          (s) => s.id == sessionId
        ).unreadMessageCount = 0;
        const db = await initializeDexieDatabase();
        db.chatSessions
          .where("id")
          .equals(sessionId)
          .modify((_) => (_.unreadMessageCount = 0));
        markSessionAsRead(sessionId);
      }
    },

    async refreshSessionsByLastMessageDate() {
      const lastMessageDateStr = LocalStorageUtil.getItem(
        `lastMessageDate-${this.viewingUserId}`
      );
      let lastMessageDate = null as Date;
      if (lastMessageDateStr) {
        lastMessageDate = new Date(lastMessageDateStr);
      }

      await this.fetchSessionsFromAPI(lastMessageDate);
      LocalStorageUtil.setItem(
        `lastMessageDate-${this.viewingUserId}`,
        new Date().toString()
      );
    },
    async refreshSessions() {
      try {
        this.loading = true;
        await this.loadLocalSessions();
        this.loading = false;
        await this.fetchSessionsFromAPI(null);
      } catch (e) {
        console.error(e);
      }
    },
    async refreshMessages(sessionId: string) {
      await this.loadLocalMessages(sessionId);
      await this.fetchMessagesFromAPI(sessionId);
    },
    async fetchSessionsFromAPI(lastMessageDate: Date | null) {
      const db = await initializeDexieDatabase();
      const localDbSessionData = await db.chatSessions
        .where("participantIds")
        .equals(this.viewingUserId)
        .reverse()
        .sortBy("id");
      let hasMore = true;
      let cursor = null as string;
      const chatSessionIds = [];
      const updatedChatSessions = [] as DexieChatSession[];
      while (hasMore) {
        const currentBatchData = this.getSessionRange(
          localDbSessionData,
          cursor,
          this.messageSyncPaginationLimit
        );
        const checksum =
          currentBatchData == null
            ? ""
            : this.getHashFromSessions(currentBatchData);
        const response = await getSessions(
          cursor,
          this.messageSyncPaginationLimit,
          checksum,
          lastMessageDate
        );
        if (response.results != null && response.results.length > 0) {
          chatSessionIds.push(...response.results.map((s) => s.id));
          updatedChatSessions.push(
            ...response.results.map((s) => new DexieChatSession(s))
          );
        } else {
          if (currentBatchData != null) {
            chatSessionIds.push(...currentBatchData.map((s) => s.id));
          }
        }
        if (response.cursor != null) {
          cursor = response.cursor;
        }
        if (response.hasMore != null) {
          hasMore = response.hasMore;
        } else {
          hasMore = false;
        }
      }
      if (lastMessageDate == null) {
        await db.chatSessions.where("id").noneOf(chatSessionIds).delete();
      }
      if (updatedChatSessions.length > 0) {
        await this.updateLocalSessions(updatedChatSessions);
      }
    },
    async loadLocalMessages(sessionId: string) {
      const db = await initializeDexieDatabase();
      var data = await db.sessionMessages
        .where("sessionID")
        .equals(sessionId)
        .toArray();
      if (!this.messages[this.viewingUserId]) {
        this.messages[this.viewingUserId] = {};
      }
      this.messages[this.viewingUserId][sessionId] = data;
      return this.messages[this.viewingUserId][sessionId];
    },
    async fetchMessagesFromAPI(sessionId: string) {
      const localDbMessageData = await this.loadLocalMessages(sessionId);
      if (localDbMessageData && localDbMessageData.length > 0) {
        localDbMessageData.sort((a, b) =>
          Number.parseInt(b.id) < Number.parseInt(a.id) ? 1 : -1
        );
      }
      let hasMore = true;
      let cursor = null as string;
      const updatedMessages = [];
      while (hasMore) {
        const currentBatchData = this.getMessageRange(
          localDbMessageData,
          cursor,
          this.messageSyncPaginationLimit
        );
        const checksum =
          currentBatchData == null
            ? ""
            : this.getHashFromMessages(currentBatchData);
        const response = await getMessages(
          sessionId,
          cursor,
          this.messageSyncPaginationLimit,
          checksum
        );
        if (response.results != null && response.results.length > 0) {
          const receivedMessages = response.results.map((m) => {
            m.status = MessageStatus.Sent;
            return m;
          });
          updatedMessages.push(...receivedMessages);
        }
        if (response.cursor != null) {
          cursor = response.cursor;
        }
        if (response.hasMore != null) {
          hasMore = response.hasMore;
          if (response.results && response.results.length === 0) {
            hasMore = false;
          }
        } else {
          hasMore = false;
        }
      }
      if (updatedMessages.length > 0) {
        this.persistLocalMessages(sessionId, updatedMessages);
      }
    },
    async persistLocalMessages(sessionId: string, messages: DexieMessage[]) {
      if (!this.messages[this.viewingUserId]) {
        this.messages[this.viewingUserId] = {};
      }
      if (!this.messages[this.viewingUserId][sessionId]) {
        this.messages[this.viewingUserId][sessionId] = [];
      }
      const db = await initializeDexieDatabase();
      const messagesString = JSON.stringify(messages);
      const newMessages = JSON.parse(messagesString);
      await db.sessionMessages.bulkPut(newMessages).catch((e) => {
        var a = 4;
      });

      this.messages[this.viewingUserId][sessionId] = await db.sessionMessages
        .where("sessionID")
        .equals(sessionId)
        .sortBy("dateSent");
    },
    async sortLocalSessions(sessions: DexieChatSession[]) {
      this.sessionsByViewer[this.viewingUserId] = [...sessions].sort((a, b) => {
        const dateA = new Date(a.lastMessageDate || a.createdAt);
        const dateB = new Date(b.lastMessageDate || b.createdAt);
        return dateB.getTime() - dateA.getTime();
      });
    },

    async updateLocalSessions(sessions: DexieChatSession[]) {
      const db = await initializeDexieDatabase();
      await db.chatSessions
        .bulkPut(sessions)
        .catch(Dexie.BulkError, function (e) {
          console.error(e.failures.length + " items failed to be added");
        });
      this.sessionsByViewer[this.viewingUserId] = await db.chatSessions
        .where("participantIds")
        .equals(this.viewingUserId)
        .reverse()
        .sortBy("lastMessageDate");
      this.sortLocalSessions(this.sessionsByViewer[this.viewingUserId]);
    },
    removeLocalSession(sessionId: string) {
      this.sessionsByViewer[this.viewingUserId] = this.sessionsByViewer[
        this.viewingUserId
      ].filter((s) => s.id !== sessionId);
    },
    getMessageFromAPI(messageId: string, withAttachmentUrls: boolean) {
      return getMessage(messageId, withAttachmentUrls);
    },
    getSessionRange(
      data: DexieChatSession[],
      identifier: string,
      itemCount: number
    ) {
      if (data.length === 0) {
        return null;
      }
      if (!identifier || identifier.length === 0) {
        const end = data.length < itemCount ? data.length : itemCount;
        const result = data.slice(0, end);
        return result;
      } else {
        const start = data.findIndex((element) => element.id === identifier);
        if (start < 0) {
          return null;
        }

        const end =
          data.length - start < itemCount ? data.length : itemCount + start;
        const result = data.slice(start, end);
        return result;
      }
    },
    getMessageRange(
      data: DexieMessage[],
      identifier: string,
      itemCount: number
    ) {
      if (!data || data.length == undefined || data.length === 0) {
        return null;
      }
      if (!identifier || identifier.length === 0) {
        const end = data.length < itemCount ? data.length : itemCount;
        const result = data.slice(0, end);
        return result;
      } else {
        const start = data.findIndex((element) => element.id === identifier);
        if (start < 0) {
          return null;
        }

        const end =
          data.length - start < itemCount ? data.length : itemCount + start;
        const result = data.slice(start, end);
        return result;
      }
    },

    getHashFromSessions(sessions: DexieChatSession[]) {
      const sessionHashInfo = sessions.map((s) => {
        let milliseconds = 0;
        if (s.lastMessageDate) {
          const lastMessageDate = new Date(s.lastMessageDate);
          milliseconds = lastMessageDate.getTime();
        }
        return `${s.id}${milliseconds}${s.clientCategory}`;
      });

      return this.getHash(sessionHashInfo);
    },
    getHashFromMessages(messages: DexieMessage[]) {
      const data = messages.filter((m) => m.id != null).map((m) => m.id);
      return this.getHash(data);
    },
    getHash(data: string[]) {
      const joinedData = data.join("");
      return Md5.hashStr(joinedData).toUpperCase();
    },
    changeCommunicationInfoModalVisibility(isVisible: boolean) {
      this.communicationInfoModalVisible = isVisible;
    },
    async logout() {
      this.$state = initialState();
    },
  },
});

export type MessagingState = {
  sessionsByViewer: { [key: string]: DexieChatSession[] };
  selectedSession: DexieChatSession;
  messages: { [key: string]: { [key: string]: DexieMessage[] } };
  loading: boolean;
  viewingUserId: string;
  selectedCategory: String;
  selectedMessage: DexieMessage;
  searchQuery: string;
  initializationStatus: string;
  unsentMessages: { [key: string]: { [key: string]: string } };
  communicationInfoModalVisible: boolean;
};

export const initialState = (): MessagingState => ({
  loading: true,
  sessionsByViewer: {},
  selectedSession: null,
  messages: {},
  viewingUserId: null,
  selectedCategory: "Yeni",
  selectedMessage: null,
  searchQuery: "",
  initializationStatus: "initial",
  unsentMessages: {},
  communicationInfoModalVisible: false,
});
