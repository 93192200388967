import { VerticalEnum } from "~/model/common";
import { ClientMatchingPreferences } from "../preferences/client";
import type { UserType } from "~/model/user";

export class MatchingProfessionalsRequest {
  vertical: VerticalEnum;
  userType: UserType;
  clientMatchingPreferences: ClientMatchingPreferences;

  constructor(
    vertical: VerticalEnum,
    userType: UserType,
    clientMatchingPreferences: ClientMatchingPreferences
  ) {
    this.vertical = vertical;
    this.userType = userType;
    this.clientMatchingPreferences = clientMatchingPreferences;
  }
}
