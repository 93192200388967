import { defineStore } from "pinia";
import { VerticalEnum } from "~/model/common";

export interface Vertical {
  title: string;
  description: string;
  cta: string;
  picture: string;
  enumValue: VerticalEnum;
}

export const selectableVerticals: Vertical[] = [
  {
    title: "Mental sağlık",
    description:
      "Klinik psikologlardan terapi alın, psikolojik danışmanlarla görüşün.",
    cta: "Mental sağlık ile başlayın",
    picture: "/images/mental-vertical.png",
    enumValue: VerticalEnum.MentalHealth,
  },
  {
    title: "Beslenme ve diyet",
    description:
      "Diyetisyen eşliğinde size özel hazırlanan beslenme programlarını takip edin.",
    cta: "Beslenme ve diyet ile başlayın",
    picture: "/images/nutrition-vertical.png",
    enumValue: VerticalEnum.Nutrition,
  },
  {
    title: "Fizyoterapi",
    description:
      "Fizyoterapi danışmanlığı alın, size özel hazırlanan seansları ve egzersiz programlarını evinizde yapın.",
    cta: "Fizyoterapi ile başlayın",
    picture: "/images/physio-vertical.png",
    enumValue: VerticalEnum.PhysicalHealth,
  },
];
export const useVerticalStore = defineStore("vertical", {
  state: () => ({
    _loading: false as boolean,
    _verticals: [] as Vertical[],
    _selected: null as string | null,
    _selectedEnumValue: null as VerticalEnum | null,
  }),
  getters: {
    verticals: (state) => {
      return state._verticals;
    },
    loading: (state) => {
      return state._loading;
    },
    selected: (state) => {
      return state._selected;
    },
    selectedEnumValue: (state) => {
      return state._selectedEnumValue;
    },
  },
  actions: {
    initialize() {
      this._loading = true;
      this._verticals = selectableVerticals;
      this._loading = false;
    },
    selectVertical(vertical) {
      this._selected = vertical.title;
      this._selectedEnumValue = vertical.enumValue;
    },
    selectVerticalByEnumValue(enumValue: VerticalEnum) {
      const vertical = selectableVerticals.find(
        (v) => v.enumValue === enumValue
      );
      this._selected = vertical.title;
      this._selectedEnumValue = enumValue;
    },
  },
});
