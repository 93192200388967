import { defineNuxtPlugin } from "#app";
import UAParser from "ua-parser-js";

export default defineNuxtPlugin((nuxtApp) => {
  const headers = useRequestHeaders();
  const userAgent =
    process.server && headers["user-agent"] != null
      ? headers["user-agent"]
      : navigator.userAgent;
  const parser = new UAParser(userAgent);

  nuxtApp.provide("uaParser", parser);
});
