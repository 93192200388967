class SecurityUtils {
    static async sha256ofString(rawNonce: string) {
        const encoder = new TextEncoder();
        const data = encoder.encode(rawNonce);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        return hashBuffer;
    }
    static generateNonce() {
        var nonce = '';
        var chars = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = 0; i < 32; i++) {
            nonce += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return nonce;
    }

}

export default SecurityUtils;