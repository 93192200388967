import axios from "axios";
import { defineNuxtPlugin } from "#app";
import type { Token } from "~/model/common";
import { isTokenExpired } from "~/utils/session";
import { refreshAuthToken } from "~/helpers/api/auth";
import { useAuthStore } from "~/store/auth";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const maxRetryCount = 2;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      originalRequest &&
      originalRequest.url !== "/api/auth/LogIn" &&
      error.response?.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      originalRequest._retryCount = (originalRequest._retryCount || 0) + 1;

      const refreshToken = LocalStorageUtil.getItem(
        LocalStorageKeys.RefreshToken
      ) as Token;

      if (
        refreshToken &&
        !isTokenExpired(refreshToken) &&
        originalRequest._retryCount <= maxRetryCount
      ) {
        try {
          const authStore = useAuthStore();

          const response = await refreshAuthToken(refreshToken.token);

          const newToken: Token = {
            token: response.token,
            expires: response.tokenExpire,
          };

          const newRefreshToken: Token = {
            token: response.refreshToken,
            expires: response.refreshTokenExpire,
          };

          authStore.syncAuthStoreAndSession(newToken, newRefreshToken);
          originalRequest.headers.Authorization = `Bearer ${newToken.token}`;

          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error("Failed to refresh token", refreshError);
          clearSession();
          window.location.href = routes.signin.path;
          throw refreshError;
        }
      } else {
        console.error("Refresh token is expired or not available");
        clearSession();
        window.location.href = routes.signin.path;
      }
    }

    return Promise.reject(error);
  }
);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide("axios", axiosInstance);
});

export { axiosInstance };
