import mixpanelBrowser from "mixpanel-browser";
import { MeetingRightState } from "~/model/user/plan/meetingRight";
var mixpanel = {
  _base: {
    token: null,
    m: null,
  },
  init(key) {
    this._base.m = mixpanelBrowser;
    this._base.token = key;

    this._base.m.init(this._base.token);

    const referrerUrl = document.referrer;
    if (referrerUrl) {
      this._base.m.people.set("AcquisitionWebUrl", referrerUrl);
      this._base.m.register({
        AcquisitionWebUrl: referrerUrl,
      });
    }

    return this;
  },
  setUser(user) {
    this._base.m.identify(user.id);
    this._base.m.alias(user.id, user.id);
    this._base.m.people.set("Name", user.firstname);
    this._base.m.people.set("Surname", user.lastname);
    this._base.m.people.set("UserType", user.userType);
    this._base.m.people.set("State", user.userState);
    this._base.m.people.set("Email", user.email);
    this._base.m.people.set("CreatedAt", user.createdAt);
    this._base.m.people.set("RegistrationSource", user.registrationSource);
    this._base.m.people.set("SponsorshipID", user.planV2?.sponsorId);
    this._base.m.people.set(
      "Plan.Type",
      user.planV2?.sponsorshipType.toString()
    );
    this._base.m.people.set(
      "Plan.ValidUntil",
      user.planV2?.validUntil != null ? user.planV2?.validUntil.toString() : ""
    );
    this._base.m.people.set(
      "Enrollments",
      user.enrollments?.map((v) => v.vertical.toString())
    );
    this._base.m.people.set(
      "ActiveMeetingRightCount",
      user.planV2?.meetingRights?.filter(
        (e) => e.state == MeetingRightState.Active
      ).length
    );
    this._base.m.people.set(
      "UsedMeetingRightCount",
      user.planV2?.meetingRights?.filter(
        (e) => e.state == MeetingRightState.Used
      ).length
    );
    this._base.m.register({
      Name: user.firstname,
      Surname: user.lastname,
      UserType: user.userType,
      UserState: user.userState,
      Email: user.email,
      CreatedAt: user.createdAt,
      RegistrationSource: user.registrationSource,
      SponsorshipID: user.planV2?.sponsorId,
      "Plan.Type": user.planV2?.sponsorshipType.toString(),
      "Plan.ValidUntil":
        user.planV2?.validUntil != null
          ? user.planV2?.validUntil.toString()
          : "",
      Enrollments: user.enrollments?.map((v) => v.vertical.toString()),
      ActiveMeetingRightCount: user.planV2?.meetingRights?.filter(
        (e) => e.state == MeetingRightState.Active
      ).length,
      UsedMeetingRightCount: user.planV2?.meetingRights?.filter(
        (e) => e.state == MeetingRightState.Used
      ).length,
    });

    return this;
  },
  clearUser() {
    this._base.m.reset();

    return this;
  },
  track(eventName, obj) {
    this._base.m.track(eventName, obj);

    return this;
  },
  timeEvent(eventName) {
    this._base.m.time_event(eventName);

    return this;
  },
};

export default mixpanel;
