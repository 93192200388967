import type { ProfessionalProfile } from "../common/professional/professionalProfile";
import type { TimeSlot } from "../matching/preferences";

export class ClientSchedulingState {
  professional: ProfessionalProfile;
  availableTimeSlots: TimeSlot[];
  selectedTimeSlot?: TimeSlot;

  constructor(
    professional: ProfessionalProfile,
    availableTimeSlots: TimeSlot[]
  ) {
    this.professional = professional;
    this.availableTimeSlots = availableTimeSlots;
  }
}
