export default definePayloadPlugin((nuxtApp) => {
  definePayloadReducer(
    "JSONifiable",
    (data) =>
      data &&
      typeof data === "object" &&
      "toJSON" in data &&
      JSON.stringify(data.toJSON())
  );
  definePayloadReviver("JSONifiable", (data) => JSON.parse(data));
});
