import { defineStore } from "pinia";
import { retrieveJourney } from "~/helpers/api/journey";
import type { UserJourney } from "~/model/user/journey";
import {
  getProfessionalById,
  getUserProfile,
  updateMatchingPreference,
} from "~/helpers/api/user";
import { UserType, type UserProfile } from "~/model/user";
import type { CareTeamItem } from "~/model/user/careTeam";
import { useStorage } from "@vueuse/core";
import type { ProfessionalProfile } from "~/model/common/professional/professionalProfile";
import { isAfter } from "date-fns";

export const useUserStore = defineStore("user", {
  state: () => ({
    isLoading: false,
    userProfile: null as UserProfile | null,
    journey: useStorage("journey", null as UserJourney | null),
    professionalProfile: null as ProfessionalProfile | null,
  }),
  getters: {
    careTeam(): CareTeamItem[] | null {
      return this.userProfile?.careTeamSummary?.careTeamMembers;
    },
    careTeamMemberUserTypes(): UserType[] {
      const careTeam = this.careTeam;
      if (!careTeam || careTeam.length === 0) {
        return [];
      }

      return careTeam
        .filter((m) => m.role !== UserType.Support)
        .map((m) => m.role);
    },
    getCareTeamMemberById: (state) => (id: string) => {
      return state.userProfile?.careTeamSummary?.careTeamMembers?.find(
        (member) => member.id === id
      );
    },
    getCareTeamMemberByUserType: (state) => (userType: UserType) => {
      return state.userProfile?.careTeamSummary?.careTeamMembers?.find(
        (member) => member.role === userType
      );
    },
    getNextMeetingWithUser: (state) => (userId: string) => {
      return state.journey?.meetings.find(
        (meeting) =>
          isAfter(meeting.endDate, new Date().toISOString()) &&
          meeting.participantIds.indexOf(userId) > -1
      );
    },
    getFirstProvider: (state) => {
      return state.userProfile?.careTeamSummary?.careTeamMembers?.find(
        (member) => member.role !== UserType.Support
      );
    },
  },
  actions: {
    async loadUserProfile(markAsLoading = true) {
      if (this.userProfile == null) {
        if (markAsLoading) {
          this.isLoading = true;
        }
        try {
          this.userProfile = (await getUserProfile()) as any;
        } catch (e) {}
        if (markAsLoading) {
          this.isLoading = false;
        }
      }
    },
    async refreshUserProfile() {
      this.isLoading = true;
      try {
        this.userProfile = (await getUserProfile()) as any;
      } catch (e) {}
      this.isLoading = false;
    },
    async refreshProfessionalProfile(id: string) {
      this.isLoading = true;
      try {
        await this.loadUserProfile(false);
        this.professionalProfile = (await getProfessionalById(id)) as any;
      } catch (e) {}
      this.isLoading = false;
    },
    async loadProfessionalProfile(id: string) {
      if (this.professionalProfile == null) {
        this.isLoading = true;

        try {
          this.professionalProfile = (await getProfessionalById(id)) as any;
        } catch (e) {}

        this.isLoading = false;
      }
    },
    async logout() {
      this.userProfile = null;
    },
    async retrieveJourney() {
      this.journey = await retrieveJourney();
      return this.journey;
    },
    async updateAvailableForMatching() {
      const newValue =
        !this.professionalProfile?.matchingPreferences.availableForMatching;

      try {
        this.isLoading = true;
        await updateMatchingPreference({
          availableForMatching: newValue,
        });

        this.professionalProfile.matchingPreferences.availableForMatching =
          newValue;
      } catch (e) {
        console.error(e);
      }

      this.isLoading = false;
    },
    setProfessionalProfile(profile: ProfessionalProfile) {
      this.professionalProfile = profile;
    },
  },
});
