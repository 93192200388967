import { default as indexr5WuvrudkLMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/alan-secimi/index.vue?macro=true";
import { default as index4tkHgJtxscMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/ayarlarim/index.vue?macro=true";
import { default as index6Jry9qwrijMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/danisman-ekibim/index.vue?macro=true";
import { default as indexOeVdVjcZhxMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/etkinlestir/index.vue?macro=true";
import { default as indexzKXHdnosD0Meta } from "D:/a/r1/a/_SalusWebsite/pages/app/faturalar/index.vue?macro=true";
import { default as indexjhZSW3yNgTMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/giris/index.vue?macro=true";
import { default as indexN8r5mruhLoMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/indir/index.vue?macro=true";
import { default as indexYwkY782we8Meta } from "D:/a/r1/a/_SalusWebsite/pages/app/kayit-ol/index.vue?macro=true";
import { default as indexQgtMEersVzMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/mesajlarim/index.vue?macro=true";
import { default as indexNTanXVORlZMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/on-gorusme/index.vue?macro=true";
import { default as _91_91id_93_93gR8mHmV8tSMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/online-gorusme/[[id]].vue?macro=true";
import { default as indexxl4qQYC01pMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/profilim/index.vue?macro=true";
import { default as indexlCPa0wsw90Meta } from "D:/a/r1/a/_SalusWebsite/pages/app/randevu-ayarla/index.vue?macro=true";
import { default as indexvX8z6TOWReMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/randevu-gecmisim/index.vue?macro=true";
import { default as indexDutBs2TMNJMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/satin-al/index.vue?macro=true";
import { default as indexqzd2Ck6veiMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/seni-taniyalim/index.vue?macro=true";
import { default as _91slug_938Tmghx0XmNMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/sorular/[slug].vue?macro=true";
import { default as indexFcvULnRjFAMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/uygun-saatlerim/index.vue?macro=true";
import { default as _91id_93Fv7wpRMyviMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/uzman/[id].vue?macro=true";
import { default as indexbRLOMCdny5Meta } from "D:/a/r1/a/_SalusWebsite/pages/app/uzmanla-esles/index.vue?macro=true";
import { default as indexr2NHpKQAfZMeta } from "D:/a/r1/a/_SalusWebsite/pages/app/yolculugum/index.vue?macro=true";
import { default as _91slug_93pxf6Mnf4ntMeta } from "D:/a/r1/a/_SalusWebsite/pages/belge/[slug].vue?macro=true";
import { default as _91slug_937qJuKLYY88Meta } from "D:/a/r1/a/_SalusWebsite/pages/blog/[slug].vue?macro=true";
import { default as indexKFysNiWc9IMeta } from "D:/a/r1/a/_SalusWebsite/pages/blog/index.vue?macro=true";
import { default as _91slug_93JdqgXN8SiFMeta } from "D:/a/r1/a/_SalusWebsite/pages/blog/kategori/[slug].vue?macro=true";
import { default as indexm9sZNSDQZoMeta } from "D:/a/r1/a/_SalusWebsite/pages/grid/index.vue?macro=true";
import { default as indexWktwIN3xTBMeta } from "D:/a/r1/a/_SalusWebsite/pages/hakkimizda/index.vue?macro=true";
import { default as indexveLT0duDOtMeta } from "D:/a/r1/a/_SalusWebsite/pages/iletisim/index.vue?macro=true";
import { default as indexlwQab1ROw7Meta } from "D:/a/r1/a/_SalusWebsite/pages/index.vue?macro=true";
import { default as index5Xcdu8gfs6Meta } from "D:/a/r1/a/_SalusWebsite/pages/noposter/index.vue?macro=true";
import { default as indexZRDKy7Q8dQMeta } from "D:/a/r1/a/_SalusWebsite/pages/online-gorusme/index.vue?macro=true";
import { default as _91code_93WkGR0IODQQMeta } from "D:/a/r1/a/_SalusWebsite/pages/reset-password/[code].vue?macro=true";
import { default as _91_91category_93_93fD2qJOhIk7Meta } from "D:/a/r1/a/_SalusWebsite/pages/sikca-sorulan-sorular/kategori/[[category]].vue?macro=true";
import { default as indexVixFp41YrZMeta } from "D:/a/r1/a/_SalusWebsite/pages/sirketler-icin/index.vue?macro=true";
import { default as _91kategori_slug_93h3cZWlIV2iMeta } from "D:/a/r1/a/_SalusWebsite/pages/testler/[kategori_slug].vue?macro=true";
import { default as _91id_93z1bCBeu0qnMeta } from "D:/a/r1/a/_SalusWebsite/pages/testler/[slug]/[id].vue?macro=true";
import { default as indexf7MuGyxTA3Meta } from "D:/a/r1/a/_SalusWebsite/pages/uzmanlar-icin/index.vue?macro=true";
import { default as _91_46_46_46params_93BVYf9sQJUQMeta } from "D:/a/r1/a/_SalusWebsite/pages/uzmanlarimiz/[...params].vue?macro=true";
import { default as _91_46_46_46params_93x7jSGfvVEFMeta } from "/pages/uzmanlarimiz/[...params].vue?macro=true";
import { default as _91_91category_93_93O3ZW2Gi4WiMeta } from "/pages/sikca-sorulan-sorular/kategori/[[category]].vue?macro=true";
import { default as indexcK7FDEsV5bMeta } from "/pages/app/alan-secimi/index.vue?macro=true";
import { default as indexV29HfQbgpuMeta } from "/pages/app/seni-taniyalim/index.vue?macro=true";
import { default as indexDmtYar1xAuMeta } from "/pages/app/giris/index.vue?macro=true";
import { default as index2eWWp0KIqoMeta } from "/pages/app/kayit-ol/index.vue?macro=true";
import { default as index4IVZnS9mKNMeta } from "/pages/app/etkinlestir/index.vue?macro=true";
import { default as index2FJTM4L9p7Meta } from "/pages/app/uzmanla-esles/index.vue?macro=true";
import { default as indexLNibFrZsFXMeta } from "/pages/app/satin-al/index.vue?macro=true";
import { default as _91slug_93y39YsyNIMGMeta } from "/pages/app/sorular/[slug].vue?macro=true";
import { default as _91id_93q7EFjirTClMeta } from "/pages/testler/[slug]/[id].vue?macro=true";
import { default as _91_91id_93_93THd0sud18DMeta } from "/pages/app/online-gorusme/[[id]].vue?macro=true";
import { default as indexbxpFxrE69lMeta } from "/pages/app/on-gorusme/index.vue?macro=true";
import { default as indexGP7ImnSIk3Meta } from "/pages/app/randevu-ayarla/index.vue?macro=true";
import { default as component_45stubvIZ1D5aKEeMeta } from "D:/a/r1/a/_SalusWebsite/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubvIZ1D5aKEe } from "D:/a/r1/a/_SalusWebsite/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexr5WuvrudkLMeta?.name ?? "app-alan-secimi",
    path: indexr5WuvrudkLMeta?.path ?? "/app/alan-secimi",
    meta: indexr5WuvrudkLMeta || {},
    alias: indexr5WuvrudkLMeta?.alias || [],
    redirect: indexr5WuvrudkLMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/alan-secimi/index.vue").then(m => m.default || m)
  },
  {
    name: index4tkHgJtxscMeta?.name ?? "app-ayarlarim",
    path: index4tkHgJtxscMeta?.path ?? "/app/ayarlarim",
    meta: index4tkHgJtxscMeta || {},
    alias: index4tkHgJtxscMeta?.alias || [],
    redirect: index4tkHgJtxscMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/ayarlarim/index.vue").then(m => m.default || m)
  },
  {
    name: index6Jry9qwrijMeta?.name ?? "app-danisman-ekibim",
    path: index6Jry9qwrijMeta?.path ?? "/app/danisman-ekibim",
    meta: index6Jry9qwrijMeta || {},
    alias: index6Jry9qwrijMeta?.alias || [],
    redirect: index6Jry9qwrijMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/danisman-ekibim/index.vue").then(m => m.default || m)
  },
  {
    name: indexOeVdVjcZhxMeta?.name ?? "app-etkinlestir",
    path: indexOeVdVjcZhxMeta?.path ?? "/app/etkinlestir",
    meta: indexOeVdVjcZhxMeta || {},
    alias: indexOeVdVjcZhxMeta?.alias || [],
    redirect: indexOeVdVjcZhxMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/etkinlestir/index.vue").then(m => m.default || m)
  },
  {
    name: indexzKXHdnosD0Meta?.name ?? "app-faturalar",
    path: indexzKXHdnosD0Meta?.path ?? "/app/faturalar",
    meta: indexzKXHdnosD0Meta || {},
    alias: indexzKXHdnosD0Meta?.alias || [],
    redirect: indexzKXHdnosD0Meta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/faturalar/index.vue").then(m => m.default || m)
  },
  {
    name: indexjhZSW3yNgTMeta?.name ?? "app-giris",
    path: indexjhZSW3yNgTMeta?.path ?? "/app/giris",
    meta: indexjhZSW3yNgTMeta || {},
    alias: indexjhZSW3yNgTMeta?.alias || [],
    redirect: indexjhZSW3yNgTMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/giris/index.vue").then(m => m.default || m)
  },
  {
    name: indexN8r5mruhLoMeta?.name ?? "app-indir",
    path: indexN8r5mruhLoMeta?.path ?? "/app/indir",
    meta: indexN8r5mruhLoMeta || {},
    alias: indexN8r5mruhLoMeta?.alias || [],
    redirect: indexN8r5mruhLoMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/indir/index.vue").then(m => m.default || m)
  },
  {
    name: indexYwkY782we8Meta?.name ?? "app-kayit-ol",
    path: indexYwkY782we8Meta?.path ?? "/app/kayit-ol",
    meta: indexYwkY782we8Meta || {},
    alias: indexYwkY782we8Meta?.alias || [],
    redirect: indexYwkY782we8Meta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/kayit-ol/index.vue").then(m => m.default || m)
  },
  {
    name: indexQgtMEersVzMeta?.name ?? "app-mesajlarim",
    path: indexQgtMEersVzMeta?.path ?? "/app/mesajlarim",
    meta: indexQgtMEersVzMeta || {},
    alias: indexQgtMEersVzMeta?.alias || [],
    redirect: indexQgtMEersVzMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/mesajlarim/index.vue").then(m => m.default || m)
  },
  {
    name: indexNTanXVORlZMeta?.name ?? "app-on-gorusme",
    path: indexNTanXVORlZMeta?.path ?? "/app/on-gorusme",
    meta: indexNTanXVORlZMeta || {},
    alias: indexNTanXVORlZMeta?.alias || [],
    redirect: indexNTanXVORlZMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/on-gorusme/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93gR8mHmV8tSMeta?.name ?? "app-online-gorusme-id",
    path: _91_91id_93_93gR8mHmV8tSMeta?.path ?? "/app/online-gorusme/:id?",
    meta: _91_91id_93_93gR8mHmV8tSMeta || {},
    alias: _91_91id_93_93gR8mHmV8tSMeta?.alias || [],
    redirect: _91_91id_93_93gR8mHmV8tSMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/online-gorusme/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexxl4qQYC01pMeta?.name ?? "app-profilim",
    path: indexxl4qQYC01pMeta?.path ?? "/app/profilim",
    meta: indexxl4qQYC01pMeta || {},
    alias: indexxl4qQYC01pMeta?.alias || [],
    redirect: indexxl4qQYC01pMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/profilim/index.vue").then(m => m.default || m)
  },
  {
    name: indexlCPa0wsw90Meta?.name ?? "app-randevu-ayarla",
    path: indexlCPa0wsw90Meta?.path ?? "/app/randevu-ayarla",
    meta: indexlCPa0wsw90Meta || {},
    alias: indexlCPa0wsw90Meta?.alias || [],
    redirect: indexlCPa0wsw90Meta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/randevu-ayarla/index.vue").then(m => m.default || m)
  },
  {
    name: indexvX8z6TOWReMeta?.name ?? "app-randevu-gecmisim",
    path: indexvX8z6TOWReMeta?.path ?? "/app/randevu-gecmisim",
    meta: indexvX8z6TOWReMeta || {},
    alias: indexvX8z6TOWReMeta?.alias || [],
    redirect: indexvX8z6TOWReMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/randevu-gecmisim/index.vue").then(m => m.default || m)
  },
  {
    name: indexDutBs2TMNJMeta?.name ?? "app-satin-al",
    path: indexDutBs2TMNJMeta?.path ?? "/app/satin-al",
    meta: indexDutBs2TMNJMeta || {},
    alias: indexDutBs2TMNJMeta?.alias || [],
    redirect: indexDutBs2TMNJMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/satin-al/index.vue").then(m => m.default || m)
  },
  {
    name: indexqzd2Ck6veiMeta?.name ?? "app-seni-taniyalim",
    path: indexqzd2Ck6veiMeta?.path ?? "/app/seni-taniyalim",
    meta: indexqzd2Ck6veiMeta || {},
    alias: indexqzd2Ck6veiMeta?.alias || [],
    redirect: indexqzd2Ck6veiMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/seni-taniyalim/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_938Tmghx0XmNMeta?.name ?? "app-sorular-slug",
    path: _91slug_938Tmghx0XmNMeta?.path ?? "/app/sorular/:slug()",
    meta: _91slug_938Tmghx0XmNMeta || {},
    alias: _91slug_938Tmghx0XmNMeta?.alias || [],
    redirect: _91slug_938Tmghx0XmNMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/sorular/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexFcvULnRjFAMeta?.name ?? "app-uygun-saatlerim",
    path: indexFcvULnRjFAMeta?.path ?? "/app/uygun-saatlerim",
    meta: indexFcvULnRjFAMeta || {},
    alias: indexFcvULnRjFAMeta?.alias || [],
    redirect: indexFcvULnRjFAMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/uygun-saatlerim/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Fv7wpRMyviMeta?.name ?? "app-uzman-id",
    path: _91id_93Fv7wpRMyviMeta?.path ?? "/app/uzman/:id()",
    meta: _91id_93Fv7wpRMyviMeta || {},
    alias: _91id_93Fv7wpRMyviMeta?.alias || [],
    redirect: _91id_93Fv7wpRMyviMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/uzman/[id].vue").then(m => m.default || m)
  },
  {
    name: indexbRLOMCdny5Meta?.name ?? "app-uzmanla-esles",
    path: indexbRLOMCdny5Meta?.path ?? "/app/uzmanla-esles",
    meta: indexbRLOMCdny5Meta || {},
    alias: indexbRLOMCdny5Meta?.alias || [],
    redirect: indexbRLOMCdny5Meta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/uzmanla-esles/index.vue").then(m => m.default || m)
  },
  {
    name: indexr2NHpKQAfZMeta?.name ?? "app-yolculugum",
    path: indexr2NHpKQAfZMeta?.path ?? "/app/yolculugum",
    meta: indexr2NHpKQAfZMeta || {},
    alias: indexr2NHpKQAfZMeta?.alias || [],
    redirect: indexr2NHpKQAfZMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/app/yolculugum/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pxf6Mnf4ntMeta?.name ?? "belge-slug",
    path: _91slug_93pxf6Mnf4ntMeta?.path ?? "/belge/:slug()",
    meta: _91slug_93pxf6Mnf4ntMeta || {},
    alias: _91slug_93pxf6Mnf4ntMeta?.alias || [],
    redirect: _91slug_93pxf6Mnf4ntMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/belge/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_937qJuKLYY88Meta?.name ?? "blog-slug",
    path: _91slug_937qJuKLYY88Meta?.path ?? "/blog/:slug()",
    meta: _91slug_937qJuKLYY88Meta || {},
    alias: _91slug_937qJuKLYY88Meta?.alias || [],
    redirect: _91slug_937qJuKLYY88Meta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexKFysNiWc9IMeta?.name ?? "blog",
    path: indexKFysNiWc9IMeta?.path ?? "/blog",
    meta: indexKFysNiWc9IMeta || {},
    alias: indexKFysNiWc9IMeta?.alias || [],
    redirect: indexKFysNiWc9IMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JdqgXN8SiFMeta?.name ?? "blog-kategori-slug",
    path: _91slug_93JdqgXN8SiFMeta?.path ?? "/blog/kategori/:slug()",
    meta: _91slug_93JdqgXN8SiFMeta || {},
    alias: _91slug_93JdqgXN8SiFMeta?.alias || [],
    redirect: _91slug_93JdqgXN8SiFMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/blog/kategori/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexm9sZNSDQZoMeta?.name ?? "grid",
    path: indexm9sZNSDQZoMeta?.path ?? "/grid",
    meta: indexm9sZNSDQZoMeta || {},
    alias: indexm9sZNSDQZoMeta?.alias || [],
    redirect: indexm9sZNSDQZoMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/grid/index.vue").then(m => m.default || m)
  },
  {
    name: indexWktwIN3xTBMeta?.name ?? "hakkimizda",
    path: indexWktwIN3xTBMeta?.path ?? "/hakkimizda",
    meta: indexWktwIN3xTBMeta || {},
    alias: indexWktwIN3xTBMeta?.alias || [],
    redirect: indexWktwIN3xTBMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/hakkimizda/index.vue").then(m => m.default || m)
  },
  {
    name: indexveLT0duDOtMeta?.name ?? "iletisim",
    path: indexveLT0duDOtMeta?.path ?? "/iletisim",
    meta: indexveLT0duDOtMeta || {},
    alias: indexveLT0duDOtMeta?.alias || [],
    redirect: indexveLT0duDOtMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/iletisim/index.vue").then(m => m.default || m)
  },
  {
    name: indexlwQab1ROw7Meta?.name ?? "index",
    path: indexlwQab1ROw7Meta?.path ?? "/",
    meta: indexlwQab1ROw7Meta || {},
    alias: indexlwQab1ROw7Meta?.alias || [],
    redirect: indexlwQab1ROw7Meta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index5Xcdu8gfs6Meta?.name ?? "noposter",
    path: index5Xcdu8gfs6Meta?.path ?? "/noposter",
    meta: index5Xcdu8gfs6Meta || {},
    alias: index5Xcdu8gfs6Meta?.alias || [],
    redirect: index5Xcdu8gfs6Meta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/noposter/index.vue").then(m => m.default || m)
  },
  {
    name: indexZRDKy7Q8dQMeta?.name ?? "online-gorusme",
    path: indexZRDKy7Q8dQMeta?.path ?? "/online-gorusme",
    meta: indexZRDKy7Q8dQMeta || {},
    alias: indexZRDKy7Q8dQMeta?.alias || [],
    redirect: indexZRDKy7Q8dQMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/online-gorusme/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93WkGR0IODQQMeta?.name ?? "reset-password-code",
    path: _91code_93WkGR0IODQQMeta?.path ?? "/reset-password/:code()",
    meta: _91code_93WkGR0IODQQMeta || {},
    alias: _91code_93WkGR0IODQQMeta?.alias || [],
    redirect: _91code_93WkGR0IODQQMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/reset-password/[code].vue").then(m => m.default || m)
  },
  {
    name: _91_91category_93_93fD2qJOhIk7Meta?.name ?? "sikca-sorulan-sorular-kategori-category",
    path: _91_91category_93_93fD2qJOhIk7Meta?.path ?? "/sikca-sorulan-sorular/kategori/:category?",
    meta: _91_91category_93_93fD2qJOhIk7Meta || {},
    alias: _91_91category_93_93fD2qJOhIk7Meta?.alias || [],
    redirect: _91_91category_93_93fD2qJOhIk7Meta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/sikca-sorulan-sorular/kategori/[[category]].vue").then(m => m.default || m)
  },
  {
    name: indexVixFp41YrZMeta?.name ?? "sirketler-icin",
    path: indexVixFp41YrZMeta?.path ?? "/sirketler-icin",
    meta: indexVixFp41YrZMeta || {},
    alias: indexVixFp41YrZMeta?.alias || [],
    redirect: indexVixFp41YrZMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/sirketler-icin/index.vue").then(m => m.default || m)
  },
  {
    name: _91kategori_slug_93h3cZWlIV2iMeta?.name ?? "testler-kategori_slug",
    path: _91kategori_slug_93h3cZWlIV2iMeta?.path ?? "/testler/:kategori_slug()",
    meta: _91kategori_slug_93h3cZWlIV2iMeta || {},
    alias: _91kategori_slug_93h3cZWlIV2iMeta?.alias || [],
    redirect: _91kategori_slug_93h3cZWlIV2iMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/testler/[kategori_slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93z1bCBeu0qnMeta?.name ?? "testler-slug-id",
    path: _91id_93z1bCBeu0qnMeta?.path ?? "/testler/:slug()/:id()",
    meta: _91id_93z1bCBeu0qnMeta || {},
    alias: _91id_93z1bCBeu0qnMeta?.alias || [],
    redirect: _91id_93z1bCBeu0qnMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/testler/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexf7MuGyxTA3Meta?.name ?? "uzmanlar-icin",
    path: indexf7MuGyxTA3Meta?.path ?? "/uzmanlar-icin",
    meta: indexf7MuGyxTA3Meta || {},
    alias: indexf7MuGyxTA3Meta?.alias || [],
    redirect: indexf7MuGyxTA3Meta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/uzmanlar-icin/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46params_93BVYf9sQJUQMeta?.name ?? "uzmanlarimiz-params",
    path: _91_46_46_46params_93BVYf9sQJUQMeta?.path ?? "/uzmanlarimiz/:params(.*)*",
    meta: _91_46_46_46params_93BVYf9sQJUQMeta || {},
    alias: _91_46_46_46params_93BVYf9sQJUQMeta?.alias || [],
    redirect: _91_46_46_46params_93BVYf9sQJUQMeta?.redirect,
    component: () => import("D:/a/r1/a/_SalusWebsite/pages/uzmanlarimiz/[...params].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46params_93x7jSGfvVEFMeta?.name ?? "uzmanlarimiz-title-page",
    path: _91_46_46_46params_93x7jSGfvVEFMeta?.path ?? "/uzmanlarimiz/unvan/:title/sayfa/:page",
    meta: _91_46_46_46params_93x7jSGfvVEFMeta || {},
    alias: _91_46_46_46params_93x7jSGfvVEFMeta?.alias || [],
    redirect: _91_46_46_46params_93x7jSGfvVEFMeta?.redirect,
    component: () => import("/pages/uzmanlarimiz/[...params].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46params_93x7jSGfvVEFMeta?.name ?? "uzmanlarimiz-title",
    path: _91_46_46_46params_93x7jSGfvVEFMeta?.path ?? "/uzmanlarimiz/unvan/:title",
    meta: _91_46_46_46params_93x7jSGfvVEFMeta || {},
    alias: _91_46_46_46params_93x7jSGfvVEFMeta?.alias || [],
    redirect: _91_46_46_46params_93x7jSGfvVEFMeta?.redirect,
    component: () => import("/pages/uzmanlarimiz/[...params].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46params_93x7jSGfvVEFMeta?.name ?? "uzmanlarimiz-page",
    path: _91_46_46_46params_93x7jSGfvVEFMeta?.path ?? "/uzmanlarimiz/sayfa/:page",
    meta: _91_46_46_46params_93x7jSGfvVEFMeta || {},
    alias: _91_46_46_46params_93x7jSGfvVEFMeta?.alias || [],
    redirect: _91_46_46_46params_93x7jSGfvVEFMeta?.redirect,
    component: () => import("/pages/uzmanlarimiz/[...params].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46params_93x7jSGfvVEFMeta?.name ?? "uzmanlarimiz-pro",
    path: _91_46_46_46params_93x7jSGfvVEFMeta?.path ?? "/uzmanlarimiz/:proSlug/:id?",
    meta: _91_46_46_46params_93x7jSGfvVEFMeta || {},
    alias: _91_46_46_46params_93x7jSGfvVEFMeta?.alias || [],
    redirect: _91_46_46_46params_93x7jSGfvVEFMeta?.redirect,
    component: () => import("/pages/uzmanlarimiz/[...params].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46params_93x7jSGfvVEFMeta?.name ?? "uzmanlarimiz",
    path: _91_46_46_46params_93x7jSGfvVEFMeta?.path ?? "/uzmanlarimiz",
    meta: _91_46_46_46params_93x7jSGfvVEFMeta || {},
    alias: _91_46_46_46params_93x7jSGfvVEFMeta?.alias || [],
    redirect: _91_46_46_46params_93x7jSGfvVEFMeta?.redirect,
    component: () => import("/pages/uzmanlarimiz/[...params].vue").then(m => m.default || m)
  },
  {
    name: _91_91category_93_93O3ZW2Gi4WiMeta?.name ?? "sikca-sorulan-sorular",
    path: _91_91category_93_93O3ZW2Gi4WiMeta?.path ?? "/sikca-sorulan-sorular",
    meta: _91_91category_93_93O3ZW2Gi4WiMeta || {},
    alias: _91_91category_93_93O3ZW2Gi4WiMeta?.alias || [],
    redirect: _91_91category_93_93O3ZW2Gi4WiMeta?.redirect,
    component: () => import("/pages/sikca-sorulan-sorular/kategori/[[category]].vue").then(m => m.default || m)
  },
  {
    name: indexcK7FDEsV5bMeta?.name ?? "alan-secimi",
    path: indexcK7FDEsV5bMeta?.path ?? "/app/alan-secimi",
    meta: indexcK7FDEsV5bMeta || {},
    alias: indexcK7FDEsV5bMeta?.alias || [],
    redirect: indexcK7FDEsV5bMeta?.redirect,
    component: () => import("/pages/app/alan-secimi/index.vue").then(m => m.default || m)
  },
  {
    name: indexV29HfQbgpuMeta?.name ?? "seni-taniyalim",
    path: indexV29HfQbgpuMeta?.path ?? "/app/seni-taniyalim",
    meta: indexV29HfQbgpuMeta || {},
    alias: indexV29HfQbgpuMeta?.alias || [],
    redirect: indexV29HfQbgpuMeta?.redirect,
    component: () => import("/pages/app/seni-taniyalim/index.vue").then(m => m.default || m)
  },
  {
    name: indexDmtYar1xAuMeta?.name ?? "giris",
    path: indexDmtYar1xAuMeta?.path ?? "/app/giris",
    meta: indexDmtYar1xAuMeta || {},
    alias: indexDmtYar1xAuMeta?.alias || [],
    redirect: indexDmtYar1xAuMeta?.redirect,
    component: () => import("/pages/app/giris/index.vue").then(m => m.default || m)
  },
  {
    name: index2eWWp0KIqoMeta?.name ?? "kayit-ol",
    path: index2eWWp0KIqoMeta?.path ?? "/app/kayit-ol",
    meta: index2eWWp0KIqoMeta || {},
    alias: index2eWWp0KIqoMeta?.alias || [],
    redirect: index2eWWp0KIqoMeta?.redirect,
    component: () => import("/pages/app/kayit-ol/index.vue").then(m => m.default || m)
  },
  {
    name: index4IVZnS9mKNMeta?.name ?? "etkinlestir",
    path: index4IVZnS9mKNMeta?.path ?? "/app/etkinlestir",
    meta: index4IVZnS9mKNMeta || {},
    alias: index4IVZnS9mKNMeta?.alias || [],
    redirect: index4IVZnS9mKNMeta?.redirect,
    component: () => import("/pages/app/etkinlestir/index.vue").then(m => m.default || m)
  },
  {
    name: index2FJTM4L9p7Meta?.name ?? "uzmanla-esles",
    path: index2FJTM4L9p7Meta?.path ?? "/app/uzmanla-esles",
    meta: index2FJTM4L9p7Meta || {},
    alias: index2FJTM4L9p7Meta?.alias || [],
    redirect: index2FJTM4L9p7Meta?.redirect,
    component: () => import("/pages/app/uzmanla-esles/index.vue").then(m => m.default || m)
  },
  {
    name: indexLNibFrZsFXMeta?.name ?? "satin-al",
    path: indexLNibFrZsFXMeta?.path ?? "/app/satin-al",
    meta: indexLNibFrZsFXMeta || {},
    alias: indexLNibFrZsFXMeta?.alias || [],
    redirect: indexLNibFrZsFXMeta?.redirect,
    component: () => import("/pages/app/satin-al/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93y39YsyNIMGMeta?.name ?? "sorular",
    path: _91slug_93y39YsyNIMGMeta?.path ?? "/app/sorular/:slug",
    meta: _91slug_93y39YsyNIMGMeta || {},
    alias: _91slug_93y39YsyNIMGMeta?.alias || [],
    redirect: _91slug_93y39YsyNIMGMeta?.redirect,
    component: () => import("/pages/app/sorular/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93q7EFjirTClMeta?.name ?? "test",
    path: _91id_93q7EFjirTClMeta?.path ?? "/testler/:slug/:id",
    meta: _91id_93q7EFjirTClMeta || {},
    alias: _91id_93q7EFjirTClMeta?.alias || [],
    redirect: _91id_93q7EFjirTClMeta?.redirect,
    component: () => import("/pages/testler/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93THd0sud18DMeta?.name ?? "app-online-gorusme",
    path: _91_91id_93_93THd0sud18DMeta?.path ?? "/app/online-gorusme/:id",
    meta: _91_91id_93_93THd0sud18DMeta || {},
    alias: _91_91id_93_93THd0sud18DMeta?.alias || [],
    redirect: _91_91id_93_93THd0sud18DMeta?.redirect,
    component: () => import("/pages/app/online-gorusme/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexbxpFxrE69lMeta?.name ?? "app-on-gorusme",
    path: indexbxpFxrE69lMeta?.path ?? "/app/on-gorusme",
    meta: indexbxpFxrE69lMeta || {},
    alias: indexbxpFxrE69lMeta?.alias || [],
    redirect: indexbxpFxrE69lMeta?.redirect,
    component: () => import("/pages/app/on-gorusme/index.vue").then(m => m.default || m)
  },
  {
    name: indexGP7ImnSIk3Meta?.name ?? "app-randevu-ayarla",
    path: indexGP7ImnSIk3Meta?.path ?? "/app/randevu-ayarla",
    meta: indexGP7ImnSIk3Meta || {},
    alias: indexGP7ImnSIk3Meta?.alias || [],
    redirect: indexGP7ImnSIk3Meta?.redirect,
    component: () => import("/pages/app/randevu-ayarla/index.vue").then(m => m.default || m)
  },
  {
    path: "/_flow/next",
    name: "special-flow",
    alias: ["/_flow/previous"]
  },
  {
    name: component_45stubvIZ1D5aKEeMeta?.name ?? undefined,
    path: component_45stubvIZ1D5aKEeMeta?.path ?? "/app/gorusme",
    meta: component_45stubvIZ1D5aKEeMeta || {},
    alias: component_45stubvIZ1D5aKEeMeta?.alias || [],
    redirect: component_45stubvIZ1D5aKEeMeta?.redirect,
    component: component_45stubvIZ1D5aKEe
  }
]