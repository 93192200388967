import { UserSummary } from "../user/summary";
import { ClientFeedback } from "./clientFeedback";

export class Meeting {
  id?: string;
  airDate?: Date;
  startDate: Date;
  endDate?: Date;
  deletedAt?: Date;
  expiryDate?: Date;
  place?: string;
  type: MeetingType;
  participantIds?: string[];
  participants?: UserSummary[];
  creditCost?: number;
  creditApproved?: boolean;
  hasSufficentCredits?: boolean;
  latestModificationReason?: string;
  providerFeedback?: string;
  meetingHeld?: boolean;
  providerEarned?: boolean;
  providerEarnType?: ProviderEarnType;
  providerEarnedUpdateReason?: string;
  state?: MeetingState;
  cancelledBy?: MeetingActor;
  createdBy?: MeetingActor;
  clientFeedback?: ClientFeedback;
  invoicingAmount?: number;
  invoicingStatus?: MeetingInvoicingStatus;

  constructor(
    startDate: Date,
    type: MeetingType,
    id?: string,
    airDate?: Date,
    endDate?: Date,
    deletedAt?: Date,
    expiryDate?: Date,
    place?: string,
    participantIds?: string[],
    participants?: UserSummary[],
    creditCost?: number,
    creditApproved?: boolean,
    hasSufficentCredits?: boolean,
    latestModificationReason?: string,
    providerFeedback?: string,
    meetingHeld?: boolean,
    providerEarned?: boolean,
    providerEarnType?: ProviderEarnType,
    providerEarnedUpdateReason?: string,
    state?: MeetingState,
    cancelledBy?: MeetingActor,
    createdBy?: MeetingActor,
    clientFeedback?: ClientFeedback,
    invoicingAmount?: number,
    invoicingStatus?: MeetingInvoicingStatus
  ) {
    this.id = id;
    this.airDate = airDate;
    this.startDate = startDate;
    this.endDate = endDate;
    this.deletedAt = deletedAt;
    this.expiryDate = expiryDate;
    this.place = place;
    this.type = type;
    this.participantIds = participantIds;
    this.participants = participants;
    this.creditCost = creditCost;
    this.creditApproved = creditApproved;
    this.hasSufficentCredits = hasSufficentCredits;
    this.latestModificationReason = latestModificationReason;
    this.providerFeedback = providerFeedback;
    this.meetingHeld = meetingHeld;
    this.providerEarned = providerEarned;
    this.providerEarnType = providerEarnType;
    this.providerEarnedUpdateReason = providerEarnedUpdateReason;
    this.state = state;
    this.cancelledBy = cancelledBy;
    this.createdBy = createdBy;
    this.clientFeedback = clientFeedback;
    this.invoicingAmount = invoicingAmount;
    this.invoicingStatus = invoicingStatus;
  }
}
export enum MeetingType {
  PreAppointment = "PreAppointment",
  Appointment = "Appointment",
  FollowUpSession = "FollowUpSession",
  Extension = "Extension",
  IntroSession = "IntroSession",
}

export enum ProviderEarnType {
  MeetingHeld = "MeetingHeld",
  OnlyPreCall = "OnlyPreCall",
  NoPreCall = "NoPreCall",
  ClientCancelLate = "ClientCancelLate",
  ClientCancelEarly = "ClientCancelEarly",
  ProviderCancel = "ProviderCancel",
  BackofficeCancel = "BackofficeCancel",
  ClientDismissedProviderLate = "ClientDismissedProviderLate",
  ClientDismissedProviderEarly = "ClientDismissedProviderEarly",
  ClientDeletedAccountLate = "ClientDeletedAccountLate",
  ClientDeletedAccountEarly = "ClientDeletedAccountEarly",
  InsufficientFunds = "InsufficientFunds",
  BackofficeOverride = "BackofficeOverride",
  FeedbackNotSubmittedInTime = "FeedbackNotSubmittedInTime",
}

export enum MeetingActor {
  Provider = "Provider",
  Client = "Client",
  System = "System",
  Support = "Support",
}

export enum MeetingState {
  Open = "Open",
  WaitingClientFeedback = "WaitingClientFeedback",
  Cancelled = "Cancelled",
  NoShow = "NoShow",
  Completed = "Completed",
}

export enum MeetingInvoicingStatus {
  NotInvoiced = "NotInvoiced",
  Invoiced = "Invoiced",
  AlreadyInvoiced = "AlreadyInvoiced",
  B2BInvoiced = "B2BInvoiced",
}
