import { getProfessionalById } from "~/helpers/api/user";
import { getSchedulingSlots } from "~/helpers/api/meeting";
import { defineStore } from "pinia";
import Tracking from "~/utils/tracking";
import { ClientSchedulingState } from "~/model/scheduling";
import { createMeeting } from "~/helpers/api/meeting";
import { MeetingCreateRequest } from "~/model/meeting/createRequest";
import { MeetingType } from "~/model/meeting";
import { useAuthStore } from "../auth";
import { useUserStore } from "../user";
export const useSchedulingStore = defineStore("scheduling", {
  state: () => ({
    isLoading: false,
    meetingCreated: false,
    createdMeeting: null as any | null,
    currentIntroductionStep: 0 as number,
    introductionConfirmed: false as boolean,
    clientSchedulingState: null as ClientSchedulingState | null,
  }),
  getters: {},
  actions: {
    initialize() {
      this.currentIntroductionStep = 0;
    },
    goToPreviousIntroductionStep() {
      const tracking = Tracking.getInstance();
      tracking.introSession("Onboarding Progress Back", {
        FromPage: this.currentIntroductionStep.toString(),
      });
      this.currentIntroductionStep -= 1;
    },
    goToNextIntroductionStep() {
      const tracking = Tracking.getInstance();
      tracking.introSession("Onboarding Progress", {
        FromPage: this.currentIntroductionStep.toString(),
      });
      this.currentIntroductionStep += 1;
    },
    updateIntroductionConfirmation() {
      this.introductionConfirmed = !this.introductionConfirmed;
      const tracking = Tracking.getInstance();
      tracking.introSession("Onboarding Agreement Checked");
    },
    async setClientSchedulingState(professionalId: string) {
      const profile = await getProfessionalById(professionalId);
      this.clientSchedulingState = new ClientSchedulingState(profile, []);
    },
    async loadClientSchedulingTimeSlots() {
      const slots = await getSchedulingSlots(
        this.clientSchedulingState.professional.id
      );
      this.clientSchedulingState = new ClientSchedulingState(
        this.clientSchedulingState.professional,
        slots
      );
    },
    async resetStore() {
      this.isLoading = false;
      this.meetingCreated = false;
      this.clientSchedulingState = null;
      this.currentIntroductionStep = 0;
      this.introductionConfirmed = false;
      this.createdMeeting = null;
    },
    async createMeeting() {
      this.isLoading = true;
      const authStore = useAuthStore();
      const userStore = useUserStore();
      const createRequest = new MeetingCreateRequest();
      createRequest.type = MeetingType.IntroSession;
      createRequest.startDate =
        this.clientSchedulingState.selectedTimeSlot.interval.offset;
      createRequest.participantIds = [
        this.clientSchedulingState.professional.id,
        authStore.user.id,
      ];

      await createMeeting(createRequest)
        .then((meeting) => {
          this.createdMeeting = meeting;
          this.trackAddMeetingEvent();
          this.isLoading = false;
          this.meetingCreated = true;
          userStore.refreshUserProfile();
          userStore.retrieveJourney();
        })
        .catch(async () => {
          this.isLoading = false;
        });
    },

    trackAddMeetingEvent() {
      const tracking = Tracking.getInstance();
      const meeting = this.createdMeeting;
      const client = meeting.participants?.find(
        (p: any) => p.userType === "Client"
      );
      const provider = meeting.participants?.find(
        (p: any) => p.userType !== "Client"
      );

      const meetingStartDate = new Date(meeting.startDate);
      tracking.meeting("Scheduled", {
        ClientEmail: client.email,
        ProviderEMail: provider.email,
        "Scheduled Date": meeting.startDate,
        "Scheduled Day": new Date(
          meetingStartDate?.getFullYear() || 2000,
          meetingStartDate?.getMonth() + 1 || 1,
          meetingStartDate?.getDate() || 1
        ),
        "Scheduled Hour": meetingStartDate?.getHours() || 0,
        "Scheduled Minute": meetingStartDate?.getMinutes() || 0,
        "Meeting Type": meeting.type,
        "Meeting Id": meeting.id,
        Source: "lobby",
        ProviderType: provider.userType,
      });
    },
  },
});
