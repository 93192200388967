import { axiosInstance } from "~/plugins/axios";
import type { User } from "~/model/user";
import type { DismissProfessionalRequest } from "~/model/user/journey/dismissProfessionalRequest";
import type { UserJourney } from "~/model/user/journey";

const apiPathPrefix = "/api/journey";

export const assignProfessional = async (id: String): Promise<any> => {
  let body = {
    id: id,
  };
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/professionals/Assign`,
    body
  );
  return data;
};

export const dismissProfessional = async (
  dismissProfessionalRequest: DismissProfessionalRequest
): Promise<User> => {
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/professionals/Dismiss`,
    dismissProfessionalRequest
  );
  return data;
};

export const retrieveJourney = async (): Promise<UserJourney> => {
  const { data } = await axiosInstance.post(
    `${apiPathPrefix}/RetrieveJourney`,
    {}
  );
  return data;
};
