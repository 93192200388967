import { HeltiaBase, VerticalEnum } from "~/model/common";
import { MeetingType } from "~/model/meeting";
import type { UserType } from "~/model/user";

export class MeetingConfiguration extends HeltiaBase {
  vertical: VerticalEnum;
  userType: UserType;
  meetingType: MeetingType;
  displayName: string;
  duration: number;

  isIntroSession(): boolean {
    return this.meetingType === MeetingType.IntroSession;
  }
}

export class ProviderConfiguration extends HeltiaBase {
  vertical: VerticalEnum;
  userType: UserType;
  titles: string[];
  description: string;
  meetings: MeetingConfiguration[];
  category: string;
  categoryDescription: string[];
}

export class VerticalConfiguration extends HeltiaBase {
  vertical: VerticalEnum;
  providers: ProviderConfiguration[];
  multipleProviderTypeAssignment: boolean;
  displayName: string;
  description: string;

  canScheduleIntroSession(): boolean {
    const firstProviderConf = this.providers?.[0] ?? null;
    if (firstProviderConf) {
      const firstMeetingConf = firstProviderConf.meetings?.[0] ?? null;
      return firstMeetingConf?.isIntroSession() ?? false;
    }
    return false;
  }
}
